import { Component } from '@angular/core';

import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'help-tour',
  templateUrl: './help-tour.component.html',
  styleUrls: ['./help-tour.component.scss']
})
export class HelpTourComponent {

  constructor(
    private lang: TranslationService
  ) { }

  translate(str: string) {
    return this.lang.translate(str);
  }

}
