import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [

  {
    path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', permission: 'noPermNeeded', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  { path: '/kundenverwaltung', title: 'Kundenverwaltung', icon: 'ft-users', class: '', permission: 'erp-crm-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/aufträge', title: 'Aufträge', icon: 'ft-clipboard', class: '', permission: 'erp-orders-menuItem', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '/buchhaltung', title: 'Buchhaltung', icon: 'ft-book-open', class: 'has-sub', permission: 'erp-accounting-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/buchhaltung/angebote', title: 'Angebote', icon: 'ft-file submenu-icon', class: '', permission: 'erp-accounting-offers-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/einnahmen', title: 'Einnahmen', icon: 'ft-file-plus submenu-icon', class: '', permission: 'erp-accounting-revenues-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/ausgaben', title: 'Ausgaben', icon: 'ft-file-minus submenu-icon', class: '', permission: 'erp-accounting-expenses-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/zahlungserinnerungen', title: 'Zahlungserinnerungen', icon: 'ft-info submenu-icon', class: '', permission: 'erp-accounting-paymentreminders-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/mahnungen', title: 'Mahnungen', icon: 'ft-alert-circle submenu-icon', class: '', permission: 'erp-accounting-overduewarnings-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '/warenwirtschaft', title: 'Warenwirtschaft', icon: 'ft-archive', class: 'has-sub', permission: 'erp-inventory-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/warenwirtschaft/artikel', title: 'Artikel & Lagerstand', icon: 'ft-package submenu-icon', class: '', permission: 'erp-inventory-products-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/warenwirtschaft/lieferanten', title: 'Lieferanten', icon: 'ft-truck submenu-icon', class: '', permission: 'erp-inventory-suppliers-read', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/warenwirtschaft/lieferscheine', title: 'Lieferscheine', icon: 'ft-file submenu-icon', class: '', permission: 'erp-inventory-deliverynotes-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  /*{ path: '', title: 'Mitarbeiter', icon: 'ft-users', class: 'has-sub', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '#', title: 'Mitarbeiter', icon: 'ft-user submenu-icon', class: '', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '#', title: 'Lohnverrechnung', icon: 'ft-file submenu-icon', class: '', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },*/
  {
    path: '/dms', title: 'DMS', icon: 'ft-folder', class: '', permission: 'manager', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/aufgaben', title: 'Aufgabenverwaltung', icon: 'ft-layout', class: '', permission: 'noPermNeeded', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/admin', title: 'Admin-Bereich', icon: 'ft-settings', class: '', permission: 'manager', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
];

export const HROUTESen: RouteInfo[] = [

  {
    path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', permission: 'noPermNeeded', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  { path: '/kundenverwaltung', title: 'Customers', icon: 'ft-users', class: '', permission: 'erp-crm-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/aufträge', title: 'Orders', icon: 'ft-clipboard', class: '', permission: 'erp-orders-menuItem', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '/buchhaltung', title: 'Accounting', icon: 'ft-book-open', class: 'has-sub', permission: 'erp-accounting-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/buchhaltung/angebote', title: 'Offers', icon: 'ft-file submenu-icon', class: '', permission: 'erp-accounting-offers-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/einnahmen', title: 'Revenues', icon: 'ft-file-plus submenu-icon', class: '', permission: 'erp-accounting-revenues-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/ausgaben', title: 'Expenses', icon: 'ft-file-minus submenu-icon', class: '', permission: 'erp-accounting-expenses-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/zahlungserinnerungen', title: 'Paymentreminders', icon: 'ft-info submenu-icon', class: '', permission: 'erp-accounting-paymentreminders-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/buchhaltung/mahnungen', title: 'Overduewarnings', icon: 'ft-alert-circle submenu-icon', class: '', permission: 'erp-accounting-overduewarnings-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '/warenwirtschaft', title: 'Storage', icon: 'ft-archive', class: 'has-sub', permission: 'erp-inventory-menuItem', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/warenwirtschaft/artikel', title: 'Products', icon: 'ft-package submenu-icon', class: '', permission: 'erp-inventory-products-read', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/warenwirtschaft/lieferanten', title: 'Suppliers', icon: 'ft-truck submenu-icon', class: '', permission: 'erp-inventory-suppliers-read', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/warenwirtschaft/lieferscheine', title: 'Delivery bills', icon: 'ft-file submenu-icon', class: '', permission: 'erp-inventory-deliverynotes-read', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  /*{ path: '', title: 'Mitarbeiter', icon: 'ft-users', class: 'has-sub', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '#', title: 'Mitarbeiter', icon: 'ft-user submenu-icon', class: '', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '#', title: 'Lohnverrechnung', icon: 'ft-file submenu-icon', class: '', permission: 'erp-', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },*/
  {
    path: '/dms', title: 'DMS', icon: 'ft-folder', class: '', permission: 'manager', minSubType: 'enterprise', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/aufgaben', title: 'Projects', icon: 'ft-layout', class: '', permission: 'noPermNeeded', minSubType: 'business', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/admin', title: 'Admin-Area', icon: 'ft-settings', class: '', permission: 'manager', minSubType: 'startup', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
];