import { Component } from '@angular/core';

import { DataCacheService } from '../services/datacache.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

    constructor(
        private dcs: DataCacheService
    ) {

    }

    public version: string = '1.0.0';

    getSubType() {
        const perms = JSON.parse(this.dcs.load("permissions"));
        if (perms["erp"]['business']) {
            return 'business';
        } else if (perms["erp"]['enterprise']) {
            return 'enterprise';
        } else {
            return 'startup';
        }
    }
}
