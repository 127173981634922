import { Injectable } from '@angular/core';
import { DataCacheService } from './datacache.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private dcs: DataCacheService) { }

  private translations = {
    "de": {
      "de": "Deutsch",
      "en": "Englisch",
      "language": "Sprache",
      "help": "Hilfe",
      "editor": "Bearbeiter",
      "editormail": "Bearbeiter-Email",
      "error": "Fehler",
      "successful": "Erfolgreich",
      "refresh": "Aktualisieren",
      "refresh-message": "Datensätze wurden aktualisiert.",
      "data-loaded": "Datensätze wurden geladen.",
      "no-data-found": "Es wurden keine Datensätze gefunden.",
      "no-matching-data-found": "Es wurden keine passenden Datensätze gefunden.",
      "yes": "Ja",
      "no": "Nein",
      "left": "Links",
      "center": "Mitte",
      "right": "Rechts",
      "upload": "Hochladen",
      "same-as": "gleich wie",
      "category": "Kategorie",
      "logout": "Abmelden",
      "provide-valid-email": "Geben Sie eine gültige Email-Adresse an!",
      "cancel": "Abbrechen",
      "submit": "Übernehmen",
      "canceled": "Abgebrochen",
      "close": "Schließen",
      "back": "Zurück",
      "next": "Weiter",
      "show": "Anzeigen",
      "hide": "Ausblenden",
      "actions": "Aktionen",
      "settings": "Einstellungen",
      "found": "gefunden",
      "with": "mit",
      "within": "innerhalb von",
      "days": "Tagen",
      "uid": "UID",
      "create": "Erstellen",
      "edit": "Bearbeiten",
      "stop-editing": "Bearbeiten beenden",
      "delete": "Löschen",
      "add": "Hinzufügen",
      "file": "Datei",
      "download": "Herunterladen",
      "only-internal": "Nur Intern sichtbar",
      "not-provided": "Nicht angegeben",
      "required-field": "Das ist ein Pflichtfeld!",
      "submit-changes": "Änderungen übernehmen",
      "discard-changes": "Änderungen verwerfen",
      "step": "Schritt",
      "open-action": "Drag&Drop Editor öffnen",
      "notes": "Anmerkungen",
      "optional": "Optional",
      "usage": "Verwendung",
      "usage-internal": "Intern (nur für Inventur)",
      "usage-external": "Für Rechnungen und Angebote etc.",
      "preview": "Vorschau",
      "more-actions": "Weiter Optionen",
      "please-wait": "Bitte warten",
      "loading": "Wird geladen",
      "generate": "Generieren",
      "cant-undo": "Diese Aktion kann nicht rückgängig gemacht werden.",
      "locked-after": "Ihre Rechnung wird dadurch in Ihrer Buchhaltung erfasst, und kann nicht mehr geändert werden.",
      "texts": "Texte",
      "postext": "Positionstext",
      "pagebreak": "Seitenumbruch",
      "add-pagebreak": "Seitenumbruch hinzufügen",

      "datatable-show": "Zeige",
      "datatable-entrys": "Einträge",
      "datatable-datasets": "Datensätze",

      "search": "Suchen",
      "search-for": "Suchen nach...",
      "search-placeholder": "Nach Funktionen suchen...",
      "search-noresult": "Keine Ergebnisse gefunden.",
      "search-customer-database": "Kunde in Dantenbank suchen",
      "searchterm": "Suchparameter",
      "searchterm-all": "Suchparameter (oder \"alle\")",
      "searchterms-missing": "Geben Sie einen Suchparameter an.",

      "permissions": "Berechtigungen",
      "permission": "Berechtigung",
      "read": "Anzeigen",

      "price": "Preis",
      "per-unit": "pro Einheit",
      "count": "Anzahl",
      "amount": "Betrag",
      "discount": "Rabatt",
      "net": "Netto",
      "gross": "Brutto",
      "tax": "Steuer",
      "taxfree": "Steuerfrei",
      "include-taxes": "Steuern verrechnen",
      "taxrate": "Steuersatz",
      "bill-amount": "Rechnungsbetrag",
      "date": "Datum",
      "total": "Gesamt",
      "sub-total": "Zwischensumme",
      "skonto": "Skonto",
      "skonto-text": "Bei einer Zahlung innerhalb #[TAGE] Tagen gewähren wir #[SKONTO] Skonto.",

      "status": "Status",
      "open": "Offen",
      "payed": "Bezahlt",
      "payed-on": "Bezahlt am",
      "set-payed": "Zahlung erfassen",
      "invoice-created": "Rechnung erstellt",
      "payment-reminder": "Zahlungserinnerung",
      "overdue-warning": "Mahnung",
      "unknown": "Unbekannt",
      "expired": "Frist abgelaufen",

      "design-title": "Design anpassen",
      "design-news": "Nachrichten",
      "design-theme": "Designfarbe",
      "design-light": "Hell",
      "design-dark": "Dunkel",
      "design-transparent": "Transparent",
      "design-alignment": "Navigationsausrichtung",
      "design-vertical": "Vertikal",
      "design-horizontal": "Horizontal",
      "design-navheader": "Navigationsverhalten",
      "design-fixed": "Fixiert",
      "design-scroll": "Scrollend",
      "design-navColor": "Farbe",
      "design-bgImage": "Hintergrundbild",
      "design-bgColor": "Hintergrundfarbe",
      "design-enableBg": "Hintergrundbild anzeigen",
      "design-navSize": "Navigationsgröße",
      "design-nav-compact": "Kompakt",
      "design-nav-small": "Klein",
      "design-nav-medium": "Mittel",
      "design-nav-large": "Groß",
      "design-override-logo": "Logo überschreiben",

      "module-crm": "Kundenverwaltung",
      "module-crm-show-customer": "Kunde anzeigen",
      "module-crm-value-customer": "Kunde",
      "module-crm-description": "Hier finden Sie alle Stammdaten Ihrer Kunden",
      "module-crm-overview-table-adress": "Anschrift",
      "module-crm-overview-table-location": "Ort",
      "module-crm-overview-table-country": "Land",
      "module-crm-value-customernumber": "Kundennummer",
      "module-crm-value-customername": "Kundenname",
      "module-crm-value-salutation": "Anrede",
      "module-crm-value-salutation-male": "Herr",
      "module-crm-value-salutation-female": "Frau",
      "module-crm-value-salutation-none": "Keine",
      "module-crm-value-forsale": "Ansprechperson",
      "module-crm-value-adress-payment": "Rechnungsadresse",
      "module-crm-value-adress-delivery": "Lieferadresse",
      "module-crm-value-adress": "Adresse",
      "module-crm-value-street": "Adresse",
      "module-crm-value-city": "Ort",
      "module-crm-value-zip": "PLZ",
      "module-crm-value-country": "Land",
      "module-crm-value-phone": "Telefon",
      "module-crm-value-mail": "E-Mail",
      "module-crm-value-web": "Web",
      "module-crm-value-contacts-department": "Abteilung",
      "module-crm-details-total-profit": "Gesamter Umsatz (Netto)",
      "module-crm-details-total-discount": "Gewährter Rabatt",
      "module-crm-details-orders": "Aufträge",
      "module-crm-create-title": "Kunde erstellen",
      "module-crm-create-customerdata": "Kundendaten",
      "module-crm-create-contactdata": "Kontaktdaten",
      "module-crm-create-submit": "Kunde erstellen",
      "module-crm-delete": "Kunde löschen",
      "module-crm-edit-title": "Kunde bearbeiten",
      "module-crm-contacts": "Kundenkontakte",
      "module-crm-contact-create": "Kontakt erstellen",
      "module-crm-contact-delete": "Kontakt löschen",

      "module-accounting": "Buchhaltung",
      "module-accounting-offer": "Angebot",
      "module-accounting-offers": "Angebote",
      "module-accounting-offers-data": "Angebotsdaten",
      "module-accounting-offers-offernumber": "Angebotsnummer",
      "module-accounting-offers-table": "asd",
      "module-accounting-offers-text": "Angebotstext",
      "module-accounting-offers-terms": "Schlusstext",
      "module-accounting-offers-valid-til": "Gültig bis",
      "module-accounting-offers-create": "Angebot erstellen",
      "module-accounting-offers-edit": "Angebot bearbeiten",
      "module-accounting-offers-delete": "Angebot löschen",
      "module-accounting-offers-to": "Angebot an",
      "module-accounting-offer-show": "Angebot anzeigen",

      "module-accounting-invoices": "Rechnungen",
      "module-accounting-invoice": "Rechnung",
      "module-accounting-invoice-cancellation": "Stornorechnung",
      "module-accounting-invoices-billnumber": "Rechnungsnummer",
      "module-accounting-invoices-billnumber-generated": "Rechnungsnummer wird automatisch generiert",
      "module-accounting-invoices-billData": "Rechnungsdaten",
      "module-accounting-invoices-text": "Rechnungstext",
      "module-accounting-invoices-terms": "Zahlungsbedingungen",
      "module-accounting-invoices-show": "Rechnung anzeigen",
      "module-accounting-invoices-create": "Rechnung erstellen",
      "module-accounting-invoices-cancel": "Rechnung stornieren",
      "module-accounting-invoices-edit": "Rechnung bearbeiten",
      "module-accounting-invoices-delete": "Rechnung löschen",
      "module-accounting-invoices-set-payed": "Zahlungseingang erfassen",
      "module-accounting-invoices-canceled": "Storniert",
      "module-accounting-invoices-to": "Rechnung an",
      "module-accounting-invoices-type": "Rechnungsart",
      "module-accounting-invoices-date": "Rechnungsdatum",
      "module-accounting-invoices-download-bill": "Rechnung herunterladen",
      "module-accounting-invoices-download-copy": "Rechnungskopie herunterladen",

      "module-accounting-invoices-other": "Sonstige Einnahmen",
      "module-accounting-invoices-other-create": "Sonstige Einnahmen erfassen",
      "module-accounting-invoices-other-from": "Erhalten von",
      "module-accounting-invoices-other-usage": "Erhalten für",
      "module-accounting-invoices-other-amount": "Erhaltener Betrag",
      "module-accounting-invoices-other-delete": "Einnahme löschen",

      "module-accounting-payment-reminder": "Zahlungserinnerung",
      "module-accounting-payment-reminders": "Zahlungserinnerungen",
      "module-accounting-payment-reminders-create": "Zahlungserinnerung erstellen",
      "module-accounting-payment-reminders-show": "Zahlungserinnerung anzeigen",
      "module-accounting-payment-reminders-delete": "Zahlungserinnerung löschen",
      "module-accounting-payment-reminders-date": "Zahlungserinnerung am",
      "module-accounting-payment-reminders-paymenttarget": "Zahlungsziel",

      "module-accounting-overduewarning": "Mahnung",
      "module-accounting-overduewarnings": "Mahnungen",
      "module-accounting-overduewarnings-fees": "Mahngebühren",
      "module-accounting-overduewarnings-interest": "Verzugszinsen",
      "module-accounting-overduewarnings-show": "Mahnung anzeigen",
      "module-accounting-overduewarnings-create": "Mahnung erstellen",
      "module-accounting-overduewarnings-delete": "Mahnung löschen",
      "module-accounting-overduewarnings-download": "Mahnung herunterladenn",
      "module-accounting-overduewarnings-text": "Mahnungstext",
      "module-accounting-overduewarnings-upgrade": "Mahnstufe erhöhen",

      "module-accounting-expense": "Ausgabe",
      "module-accounting-expenses": "Ausgaben",
      "module-accounting-expenses-create": "Ausgabe erfassen",
      "module-accounting-expenses-edit": "Ausgabe bearbeiten",
      "module-accounting-expenses-delete": "Ausgabe löschen",
      "module-accounting-expenses-from": "Rechnung von",
      "module-accounting-expenses-download": "Rechnung herunterladen",
      "module-accounting-expenses-usage": "Rechnung für",
      "module-accounting-expenses-file": "Rechnungsdatei",

      "module-orders-order": "Auftrag",
      "module-orders": "Aufträge",
      "module-orders-ordernumber": "Auftragsnummer",
      "module-orders-oc": "AB",
      "module-orders-orderconfirmation": "Auftragsbestätigung",
      "module-orders-orderconfirmation-create": "Auftragsbestätigung erstellen",
      "module-orders-orderconfirmation-show": "Auftragsbestätigung anzeigen",
      "module-orders-show": "Auftrag anzeigen",
      "module-orders-create": "Auftrag erstellen",
      "module-orders-created": "Auftrag erstellt",
      "module-orders-edit": "Auftrag bearbeiten",
      "module-orders-delete": "Auftrag löschen",
      "module-orders-download": "Auftragsbestätigung herunterladen",

      "module-storage": "Warenwirtschaft",
      "module-storage-movements": "Lagerbewegungen",
      "module-storage-movements-create": "Buchung erstellen",
      "module-storage-movements-delete": "Buchung löschen",
      "module-storage-products-articles": "Artikel/Produkte",
      "module-storage-product": "Artikel",
      "module-storage-products": "Artikel",
      "module-storage-productnumber": "Artikelnummer",
      "module-storage-product-create": "Artikel anlegen",
      "module-storage-product-add": "Artikel hinzufügen",
      "module-storage-product-edit": "Artikel bearbeiten",
      "module-storage-product-delete": "Artikel löschen",
      "module-storage-product-remove": "Artikel entfernen",
      "module-storage-product-image": "Artikelbild",
      "module-storage-product-name": "Artikelbezeichnung",
      "module-storage-product-description": "Artikelbeschreibung",
      "module-storage-product-data": "Artikeldaten",
      "module-storage-product-tracking": "Lagerstandsverwaltung",
      "module-storage-product-track": "Lagerstandsverwaltung aktivieren",
      "module-storage-product-unittype-single": "Mengenbezeichnung (Einzeln)",
      "module-storage-product-unittype-multiple": "Mengenbezeichnung (Mehrfach)",
      "module-storage-product-customsTariffNumber": "Zolltarifnummer",
      "module-storage-suppliername": "Lieferantenname",
      "module-storage-supplier": "Lieferant",
      "module-storage-suppliers": "Lieferanten",
      "module-storage-supplier-productnumber": "Lieferantenrtikelnummer",
      "module-storage-suppliernumber": "Lieferantennummer",
      "module-storage-supplier-create": "Lieferanten erstellen",
      "module-storage-supplier-edit": "Lieferanten bearbeiten",
      "module-storage-supplier-delete": "Lieferanten löschen",
      "module-storage-supplier-show": "Lieferant anzeigen",
      "module-storage-supplier-data": "Lieferantendaten",
      "module-storage-suppliertype": "Lieferantenart",
      "module-storage-suppliertype-private": "Privatperson",
      "module-storage-suppliertype-company": "Unternehmen",
      "module-storage-price-buy": "Einkaufspreis",
      "module-storage-price-sell": "Verkaufspreis",
      "module-storage-pitch-default": "Fixstellplatz",
      "module-storage-pitch": "Stellplatz",
      "module-storage-pitches": "Stellplätze",
      "module-storage-pitches-placeholder": "Lagerort (z.B. Hochregallager, Gang 3, Regal 01, Fach 04 oder H-03-01-04)",
      "module-storage-stock": "Lagerstand",
      "module-storage-stock-min": "Mindestbestand",
      "module-storage-stock-min-formula": "(Mindestbestand = Tagesverbrauch*Lieferzeit)",
      "module-storage-book-products": "Positionen abbuchen",

      "module-storage-deliverynote": "Lieferschein",
      "module-storage-deliverynotes": "Lieferscheine",
      "module-storage-deliverynotes-create": "Lieferschein erstellen",
      "module-storage-deliverynotes-delete": "Lieferschein löschen",
      "module-storage-deliverynotes-download": "Lieferschein herunterladenn",
      "module-storage-deliverynotes-text": "Liefertext",
      "module-storage-deliverynotes-terms": "Lieferbedingungen",

      "module-extensions": "Erweiterungen",

      "module-dms": "DMS",
      "module-dms-delete": "Datei löschen",
      "module-dms-preview": "Dateivorschau",
      "module-dms-preview-text": "Wählen Sie eine Datei zum anzeigen aus",

      "subscription": "Abonnement",
      "employees": "Mitarbeiter",
      "module-admin": "Admin-Bereich",
      "module-admin-settings-subtext": "Verwalten Sie alle allgemeinen Einstellungen zu Ihrem Unternehmen",
      "module-admin-settings-accent-color": "Akzentfarbe",
      "module-admin-settings-document-layout": "Dokumentenlayout",
      "module-admin-settings-menuitem": "Im Menü anzeigen",
      "module-admin-users-subtext": "Verwalten Sie Ihre Mitarbeiter und deren Berechtigungen für Pocket-ERP",
      "module-admin-export": "Datenauswertung",
      "module-admin-export-subtext": "Werten Sie Ihre Daten aus und laden Sie sie im vorgegebenen Format (E/A, Bilanz) herunter",
      "module-admin-export-generate": "Daten laden",
      "module-admin-export-generating": "Daten werden geladen....",
      "module-admin-export-download": "Dateien herunterladen",
      "module-admin-export-evaluationtype": "Auswertungsart"
    },
    "en": {
      "de": "German",
      "en": "English",
      "language": "Language",
      "help": "Help",
      "editor": "Editor",
      "editormail": "Editormail",
      "error": "Error",
      "successful": "Successfully",
      "refresh": "Refresh",
      "refresh-message": "Datasets has been refreshed.",
      "data-loaded": "Datasets has been loaded.",
      "no-data-found": "No data records were found.",
      "no-matching-data-found": "No matching data records were found.",
      "yes": "Yes",
      "no": "No",
      "left": "Left",
      "center": "Center",
      "right": "Right",
      "upload": "Upload",
      "same-as": "Same as",
      "category": "Category",
      "logout": "Logout",
      "provide-valid-email": "Please provide a valid email adress!",
      "cancel": "Cancel",
      "submit": "Submit",
      "canceled": "Canceled",
      "close": "Close",
      "back": "Back",
      "next": "Next",
      "show": "Show",
      "hide": "Hide",
      "actions": "Actions",
      "settings": "Settings",
      "found": "found",
      "with": "with",
      "within": "within",
      "days": "Days",
      "uid": "VAT",
      "create": "Create",
      "edit": "Edit",
      "stop-editing": "Stop Editing",
      "delete": "Delete",
      "add": "Add",
      "file": "File",
      "download": "Download",
      "only-internal": "Only visible internal",
      "not-provided": "Not provided",
      "required-field": "This is a required field!",
      "submit-changes": "Save changes",
      "discard-changes": "Discard changes",
      "step": "Step",
      "open-action": "Open Drag&Drop editor",
      "notes": "Notes",
      "optional": "Optional",
      "usage": "Usage",
      "usage-internal": "Internal (only for inventory)",
      "usage-external": "For invoices and offers etc.",
      "preview": "Preview",
      "more-actions": "More options",
      "please-wait": "Please wait",
      "loading": "Loading",
      "generate": "Generate",
      "cant-undo": "You cannot undo this action",
      "locked-after": "Your invoice is then recorded in your accounting system and can no longer be changed.",
      "texts": "Texts",
      "postext": "Text",
      "pagebreak": "Pagebreak",
      "add-pagebreak": "Add Pagebreak",

      "datatable-show": "Show",
      "datatable-entrys": "Entrys",
      "datatable-datasets": "Datasets",

      "search": "Search",
      "search-for": "Search for...",
      "search-placeholder": "Search for functions...",
      "search-noresult": "No results found.",
      "search-customer-database": "Search for customer in Database",
      "searchterm": "Searchterms",
      "searchterm-all": "Searchterms (or \"all\")",
      "searchterms-missing": "Enter a search parameter.",

      "permissions": "Permissions",
      "permission": "Permission",
      "read": "Show",

      "price": "Price",
      "per-unit": "per Unit",
      "count": "Count",
      "amount": "Amount",
      "discount": "Discount",
      "net": "Net",
      "gross": "Gross",
      "tax": "Tax",
      "taxfree": "Taxfree",
      "include-taxes": "Taxes",
      "taxrate": "Taxrate",
      "bill-amount": "Total",
      "date": "Date",
      "total": "Total",
      "sub-total": "Subtotal",
      "skonto": "Cash Discount",
      "skonto-text": "For payment within #[TAGE] days we grant #[SKONTO] discount.",

      "status": "Status",
      "open": "Open",
      "payed": "Payed",
      "payed-on": "Payed on",
      "set-payed": "Set Payed",
      "invoice-created": "Invoice created",
      "payment-reminder": "Paymentreminder",
      "overdue-warning": "Overdue Warning",
      "unknown": "Unknown",
      "expired": "Deadline expired",

      "design-title": "Change Design",
      "design-news": "News",
      "design-theme": "Theme",
      "design-light": "Light",
      "design-dark": "Dark",
      "design-transparent": "Colored",
      "design-alignment": "Navigation",
      "design-vertical": "Vertical",
      "design-horizontal": "Horizontal",
      "design-navheader": "Header",
      "design-fixed": "Fixed",
      "design-scroll": "Scroll",
      "design-navColor": "Color",
      "design-bgImage": "Backgroundimage",
      "design-bgColor": "Backgroundcolor",
      "design-enableBg": "Show Backgroundimage",
      "design-navSize": "Navigation size",
      "design-nav-compact": "Compact",
      "design-nav-small": "Small",
      "design-nav-medium": "Medium",
      "design-nav-large": "Large",
      "design-override-logo": "Override logo",

      "module-crm": "Customer-Management",
      "module-crm-show-customer": "Show customer",
      "module-crm-value-customer": "Customer",
      "module-crm-description": "Here you find all the data of your customers.",
      "module-crm-value-customernumber": "Customernumber",
      "module-crm-value-customername": "Customername",
      "module-crm-value-salutation": "Salutation",
      "module-crm-value-salutation-male": "Mr.",
      "module-crm-value-salutation-female": "Ms.",
      "module-crm-value-salutation-none": "None",
      "module-crm-value-forsale": "For Sale",
      "module-crm-value-adress-payment": "Invoiceing adress",
      "module-crm-value-adress-delivery": "Delivery adress",
      "module-crm-value-adress": "Adress",
      "module-crm-value-street": "Street",
      "module-crm-value-city": "City",
      "module-crm-value-zip": "ZIP",
      "module-crm-value-country": "Country",
      "module-crm-value-phone": "Phone",
      "module-crm-value-mail": "E-Mail",
      "module-crm-value-web": "Web",
      "module-crm-value-contacts-department": "Department",
      "module-crm-details-total-profit": "Total Icome (Netto)",
      "module-crm-details-total-discount": "Total Discount",
      "module-crm-details-orders": "Orders",
      "module-crm-create-title": "Create Customer",
      "module-crm-create-customerdata": "Customerdata",
      "module-crm-create-contactdata": "Contactdata",
      "module-crm-create-submit": "Create customer",
      "module-crm-delete": "Delete customer",
      "module-crm-edit-title": "Edit customer",
      "module-crm-contacts": "Customercontacts",
      "module-crm-contact-create": "Create contact",
      "module-crm-contact-delete": "Delete contact",

      "module-accounting": "Accounting",
      "module-accounting-offers": "Offers",
      "module-accounting-offer": "Offer",
      "module-accounting-offers-data": "Offerdata",
      "module-accounting-offers-offernumber": "Offernumber",
      "module-accounting-offers-table": "asd",
      "module-accounting-offers-text": "Offer starting text",
      "module-accounting-offers-terms": "Offer closing text",
      "module-accounting-offers-valid-til": "Valid til",
      "module-accounting-offers-create": "Create offer",
      "module-accounting-offers-edit": "Edit offer",
      "module-accounting-offers-delete": "Delte offer",
      "module-accounting-offers-to": "Offer to",
      "module-accounting-offer-show": "Show offer",

      "module-accounting-invoices": "Invoices",
      "module-accounting-invoice": "Invoice",
      "module-accounting-invoice-cancellation": "Cancellation invoice",
      "module-accounting-invoices-billnumber": "Invoicenumber",
      "module-accounting-invoices-billnumber-generated": "Invoice number is generated automatically",
      "module-accounting-invoices-billData": "Invoicedata",
      "module-accounting-invoices-text": "Invoicetext",
      "module-accounting-invoices-terms": "Invoice Terms",
      "module-accounting-invoices-show": "Show invoice",
      "module-accounting-invoices-create": "Create invoice",
      "module-accounting-invoices-cancel": "Cancel invoice",
      "module-accounting-invoices-edit": "Edit invoice",
      "module-accounting-invoices-delete": "Delete invoice",
      "module-accounting-invoices-set-payed": "Post incoming payment",
      "module-accounting-invoices-canceled": "Invoice canceled",
      "module-accounting-invoices-to": "Invoice to",
      "module-accounting-invoices-type": "Invoicetype",
      "module-accounting-invoices-date": "Invoicedate",
      "module-accounting-invoices-download-bill": "Download invoice",
      "module-accounting-invoices-download-copy": "Download copy of invoice",

      "module-accounting-invoices-other": "Other Income",
      "module-accounting-invoices-other-create": "Add income",
      "module-accounting-invoices-other-from": "Received from",
      "module-accounting-invoices-other-usage": "Received for",
      "module-accounting-invoices-other-amount": "Received amount",
      "module-accounting-invoices-other-delete": "Delete income",

      "module-accounting-expense": "Expense",
      "module-accounting-expenses": "Expenses",
      "module-accounting-expenses-create": "Create expense",
      "module-accounting-expenses-edit": "Edit expense",
      "module-accounting-expenses-delete": "Delete expense",
      "module-accounting-expenses-from": "Bill from",
      "module-accounting-expenses-download": "Download expense",
      "module-accounting-expenses-usage": "Bill for",
      "module-accounting-expenses-file": "Billfile",

      "module-accounting-payment-reminder": "Paymentreminder",
      "module-accounting-payment-reminders": "Paymentreminders",
      "module-accounting-payment-reminders-create": "Create paymentreminder",
      "module-accounting-payment-reminders-show": "Show paymentreminder",
      "module-accounting-payment-reminders-delete": "Delete paymentreminder",
      "module-accounting-payment-reminders-date": "Paymentreminder date",
      "module-accounting-payment-reminders-paymenttarget": "Term of payment",

      "module-accounting-overduewarning": "Overduewarning",
      "module-accounting-overduewarnings": "Overduewarnings",
      "module-accounting-overduewarnings-fees": "Overduewarning fees",
      "module-accounting-overduewarnings-interest": "Interest on arrears",
      "module-accounting-overduewarnings-show": "Show overduewarning",
      "module-accounting-overduewarnings-create": "Create overduewarning",
      "module-accounting-overduewarnings-delete": "Delete overduewarning",
      "module-accounting-overduewarnings-download": "Download overduewarning",
      "module-accounting-overduewarnings-text": "Overduewarning text",
      "module-accounting-overduewarnings-upgrade": "Increase overduewarning",

      "module-orders-order": "Order",
      "module-orders": "Orders",
      "module-orders-ordernumber": "Ordernumber",
      "module-orders-oc": "OC",
      "module-orders-orderconfirmation": "OrderConfirmation",
      "module-orders-orderconfirmation-create": "Create OrderConfirmation",
      "module-orders-orderconfirmation-show": "Show OrderConfirmation",
      "module-orders-show": "Show order",
      "module-orders-create": "Create new order",
      "module-orders-created": "Order created",
      "module-orders-edit": "Edit order",
      "module-orders-delete": "Delete order",
      "module-orders-download": "Downlaod orderconfirmation",

      "module-storage": "Storage",
      "module-storage-movements": "Storagemovements",
      "module-storage-movements-create": "Create Storagemovements",
      "module-storage-movements-delete": "Delete Storagemovements",
      "module-storage-products-articles": "Products/Articles",
      "module-storage-product": "Commodity",
      "module-storage-products": "Products",
      "module-storage-productnumber": "Productnumber",
      "module-storage-product-create": "Create product",
      "module-storage-product-add": "Add product",
      "module-storage-product-edit": "Edit product",
      "module-storage-product-delete": "Delete product",
      "module-storage-product-remove": "Remove product",
      "module-storage-product-image": "Productimage",
      "module-storage-product-name": "Productname",
      "module-storage-product-description": "Productdescription",
      "module-storage-product-data": "Productdata",
      "module-storage-product-tracking": "Inventory management",
      "module-storage-product-track": "Enable inventory management",
      "module-storage-product-unittype-single": "Quantity name (single)",
      "module-storage-product-unittype-multiple": "Quantity name (multiple)",
      "module-storage-product-customsTariffNumber": "Customs Tariff Number",
      "module-storage-suppliername": "Suppliername",
      "module-storage-supplier": "Supplier",
      "module-storage-suppliers": "Suppliers",
      "module-storage-supplier-productnumber": "Suppliers Productnumber",
      "module-storage-suppliernumber": "Suppliernumber",
      "module-storage-supplier-create": "Create supplier",
      "module-storage-supplier-edit": "Edit supplier",
      "module-storage-supplier-delete": "Delete supplier",
      "module-storage-supplier-show": "Show supplier",
      "module-storage-supplier-data": "Supplierdata",
      "module-storage-suppliertype": "Suppliertype",
      "module-storage-suppliertype-private": "Private Person",
      "module-storage-suppliertype-company": "Company",
      "module-storage-price-buy": "Purchasing price",
      "module-storage-price-sell": "Selling price",
      "module-storage-pitch-default": "Default pitch",
      "module-storage-pitch": "Pitch",
      "module-storage-pitches": "Pitches",
      "module-storage-pitches-placeholder": "Storage location (e.g. high-bay warehouse, aisle 3, shelf 01, compartment 04 or H-03-01-04)",
      "module-storage-stock": "Stock",
      "module-storage-stock-min": "Min. Stock",
      "module-storage-stock-min-formula": "(Minimum stock = daily consumption*delivery time)",
      "module-storage-book-products": "Remove items from stock",

      "module-storage-deliverynote": "Deliverynote",
      "module-storage-deliverynotes": "Deliverynotes",
      "module-storage-deliverynotes-create": "Create deliverynote",
      "module-storage-deliverynotes-delete": "Delete deliverynote",
      "module-storage-deliverynotes-download": "Download deliverynote",
      "module-storage-deliverynotes-text": "Deliverytext",
      "module-storage-deliverynotes-terms": "Deliveryterms",

      "module-extensions": "Extensions",

      "module-dms": "DMS",
      "module-dms-delete": "Delete file",
      "module-dms-preview": "Filepreview",
      "module-dms-preview-text": "Select a file to preview",

      "subscription": "Subscription",
      "employees": "Employees",
      "module-admin": "Admin-Area",
      "module-admin-settings-subtext": "Manage all general settings for your company",
      "module-admin-settings-accent-color": "Accentcolor",
      "module-admin-settings-document-layout": "Documentlayout",
      "module-admin-settings-menuitem": "Show",
      "module-admin-users-subtext": "Manage your employees and their authorizations for Pocket-ERP",
      "module-admin-export": "Dataexport",
      "module-admin-export-subtext": "Analyze your data and download it in the specified format (P&L, balance sheet)",
      "module-admin-export-generate": "Load data",
      "module-admin-export-generating": "Loading data....",
      "module-admin-export-download": "Download files",
      "module-admin-export-evaluationtype": "Evaluationtype"
    }
  };

  public translate(stringSelector: string): string {
    const lang = this.dcs.load('lang');
    let tmp = this.translations[lang];

    if (tmp) {
      return tmp[stringSelector];
    } else {
      tmp = this.translations['de'];
      return tmp[stringSelector];
    }
  }

  public translateWithProvidedLang(stringSelector: string, lang: string = 'de'): string {
    let tmp = this.translations[lang];

    if (tmp) {
      return tmp[stringSelector];
    } else {
      tmp = this.translations['de'];
      return tmp[stringSelector];
    }
  }
}
