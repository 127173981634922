import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DataCacheService } from '../services/datacache.service';
import { HROUTES, HROUTESen } from './navigation-routes.config';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = 'Side';

  layoutSub: Subscription;

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dcs: DataCacheService
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    if (this.dcs.load('lang') == 'de') {
      this.menuItems = HROUTES;
    } else {
      this.menuItems = HROUTESen;
    }
  }

  hasPermission(permissionString): boolean {
    if (permissionString == "noPermNeeded") {
      //No permission needed
      return true;
    } if (permissionString == "manager") {
      const perms = JSON.parse(this.dcs.load('permissions'));
      return perms['manager'];
    } else {
      const parts = permissionString.split('-');
      const perms = JSON.parse(this.dcs.load('permissions'));
      
      if (parts.length == 4) {
        return perms[parts[0]][parts[1]][parts[2]][parts[3]];
      } else if (parts.length == 3) {
        return perms[parts[0]][parts[1]][parts[2]];
      } else if (parts.length == 2) {
        return perms[parts[0]][parts[1]];
      }
    }
  }

  hasSubType(type: string): boolean {
    let resp = false;
    const perms = JSON.parse(this.dcs.load('permissions'));

    if (type == "startup") {
      resp = true; //Immer true weil Mindestabo
    } else if (type == "business") {
      if (perms["erp"]["business"]) {
        resp = true;
      } else {
        //Prüfen ob evtl. enterprise-Abo
        if (perms["erp"]["enterprise"]) {
          resp = true;
        }
      }
    } else if (type == "enterprise") {
      resp = perms["erp"]["enterprise"];
    }

    return resp;
  }

  ngAfterViewInit() {

    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }


    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

}
