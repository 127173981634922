import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {

  constructor() { }

  public customers = [];

  public offers = [];
  public revenues = [];
  public revenuesOther = [];
  public expenses = [];
  public paymentreminders = [];
  public overduewarnings = [];

  public products = [];
  public suppliers = [];
  public deliverynotes = [];

  public tickets = [];
  public activeTicket = [];
  public guides = [];

  public orders = [];

  save(key: string, value: string) {
    if (key == 'lang' || localStorage.getItem(key)) {
      this.savePermanent(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  savePermanent(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  load(key: string) {
    const ses = sessionStorage.getItem(key);
    const loc = localStorage.getItem(key);

    let resp = null;

    if (ses && ses != null && ses != '') {
      resp = ses;
    } else if (loc && loc != null && loc != '') {
      resp = loc;
    }

    return resp;
  }

  clear() {
    sessionStorage.clear();

    const tmp = localStorage.getItem('dashboard');
    const tmp2 = localStorage.getItem('design');
    localStorage.clear();
    localStorage.setItem('dashboard', tmp);
    localStorage.setItem('design', tmp2);
  }

  saveDashboard(dashboard: any) {
    localStorage.setItem('dashboard', JSON.stringify(dashboard));
  }

  loadDashboard() {
    return JSON.parse(localStorage.getItem('dashboard'));
  }

  loadDesign() {
    return JSON.parse(localStorage.getItem('design'));
  }

  saveDesign(design: any) {
    this.savePermanent('design', JSON.stringify(design));
  }

  getMenuPosition() {
    var design = this.loadDesign();
    var layout = design['layout'];
    var position = layout['menuPosition'];
    return position;
  }
}
