import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES, ROUTESen } from './vertical-menu-routes.config';
import { HROUTES, HROUTESen } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { DataCacheService } from "../services/datacache.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = '';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private dcs: DataCacheService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.menuItems = ROUTES;
  }

  hasPermission(permissionString): boolean {
    if (permissionString == "noPermNeeded") {
      //No permission needed
      return true;
    } if (permissionString == "manager") {
      const perms = JSON.parse(this.dcs.load('permissions'));
      return perms['manager'];
    } else {
      const parts = permissionString.split('-');
      const perms = JSON.parse(this.dcs.load('permissions'));

      if (parts.length == 4) {
        return perms[parts[0]][parts[1]][parts[2]][parts[3]];
      } else if (parts.length == 3) {
        return perms[parts[0]][parts[1]][parts[2]];
      } else if (parts.length == 2) {
        return perms[parts[0]][parts[1]];
      }
    }
  }

  hasSubType(type: string): boolean {
    let resp = false;
    const perms = JSON.parse(this.dcs.load('permissions'));

    if (type == "startup") {
      resp = true; //Immer true weil Mindestabo
    } else if (type == "business") {
      if (perms["erp"]["business"]) {
        resp = true;
      } else {
        //Prüfen ob evtl. enterprise-Abo
        if (perms["erp"]["enterprise"]) {
          resp = true;
        }
      }
    } else if (type == "enterprise") {
      resp = perms["erp"]["enterprise"];
    }

    return resp;
  }

  get userDetails() {
    return JSON.parse(this.dcs.load('details'));
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {
    var basePath = 'assets/img/logos/';
    this.logoUrl = 'assets/img/logos/logo.png';

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        if (this.dcs.load("lang") == 'de') {
          this.menuItems = HROUTES;
        } else {
          this.menuItems = HROUTESen;
        }
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      if (this.dcs.load("lang") == 'de') {
        this.menuItems = ROUTES;
      } else {
        this.menuItems = ROUTESen;
      }
    }

    //Logo an Theme anpassen
    if (this.config.layout.variant === "Light") {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-color.png';
      } else {
        this.logoUrl = basePath + 'logo-dark.png';
      }
    } else if (this.config.layout.variant === "Dark") {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-white.png';
      } else {
        this.logoUrl = basePath + 'logo-light.png';
      }
    } else {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-white.png';
      } else {
        this.logoUrl = basePath + 'logo-white.png';
      }
    }

    //Logo an Nav-Bg anpassen
    if (this.config.layout.sidebar.backgroundColor === "black") {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-white.png';
      } else {
        this.logoUrl = basePath + 'logo-light.png';
      }
    } else if (this.config.layout.sidebar.backgroundColor === "white") {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-color.png';
      } else {
        this.logoUrl = basePath + 'logo-dark.png';
      }
    } else {
      if (this.config.layout.sidebar.collapsed) {
        this.logoUrl = basePath + 'logo-p-white.png';
      } else {
        this.logoUrl = basePath + 'logo-white.png';
      }
    }


    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
