<!-- START Notification Sidebar-->
<aside class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar" [ngClass]="{open: isOpen}">
  <a class="notification-sidebar-close" (click)="onClose()">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav ngbNav #nav="ngbNav" class="nav-tabs">
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link"><i class="ft-user"></i></a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="activity-tab" role="tabpanel" aria-expanded="true"
                aria-labelledby="base-tab1">
                <div class="col-12 text-center">
                  <img class="avatar mt-3" [src]="details['logo']" />
                  <hr>

                  <h3>{{ details['name'] }}</h3>
                  <p class="text-muted">{{ details['company_name'] }}</p>

                  <p class="details">
                    <strong>Abonnement</strong><br>
                    Pocket<sup>ERP</sup> {{ this.getSubType() | titlecase }}<br>
                    <br>
                    <strong>Status</strong><br>
                    <span class="badge badge-pill bg-light-success">AKTIV<br><small>Partnerprogramm</small></span>
                  </p>
                </div>
                <div class="actions">
                  <a (click)="this.auth.logout()" class="btn btn-block gradient-purple-bliss" id="cypress-logout">{{
                    this.translate('logout') }} <i class="ft-log-out"></i></a>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link"><i class="ft-droplet"></i></a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="settings-tab" aria-labelledby="base-tab2">
                <div class="col-12">
                  <app-customizer></app-customizer>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->