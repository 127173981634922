import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { formatDate } from '@angular/common';
import { Title } from "@angular/platform-browser";

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ApiService } from "../services/api.service";
import { DataCacheService } from "../services/datacache.service";
import { TranslationService } from "../services/translation.service";

@Component({
  selector: "file-creator",
  templateUrl: "./file-creator.component.html",
  styleUrls: ["./file-creator.component.scss"],
})
export class FileCreatorComponent implements OnInit {
  @Input()
  type: string;

  @Input()
  number: string;

  @Input()
  mode: string;

  @Input()
  orderNumber: string;

  @Input()
  customerNumber: string;

  backupNumber: string;
  payedDate: string;

  skonto: number = 0;

  overdueFees: number = 0;
  overdueInterest: number = 0;
  tmp: any;

  backupData: any;
  inputData = this.formBuilder.group({
    oid: [""],
    paymentreminderID: [""],
    overduewarningID: [0],
    overdueInvoiceAmount: [0],
    offerNumber: new FormControl("", [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]),
    revenueNumber: new FormControl("", [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]),
    customerNumber: new FormControl("", [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]),
    name: [""],
    salutation: [""],
    forSale: [""],
    adress: [""],
    delivery: [""],
    language: ["de"],
    uid: [""],
    date: new FormControl({ value: new Date().toISOString().substring(0, 10), disabled: false}),
    validTil: [""],
    payed: [""],
    skonto: [0],
    skontotage: [0],
    tax: [true],
    type: [1],
    orderNumber: new FormControl("", [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]),
    offertext: [
      "Wir bedanken uns für Ihre Anfrage und unterbreiten Ihnen hiermit folgendes Angebot:",
    ],
    footnote: [
      "Wir würden uns sehr freuen, wenn unser Angebot Ihre Zustimmung findet.",
    ],
    revenuetext: [
      "Hiermit erlauben wir uns Ihnen folgende Positionen in Rechnung zu stellen:",
    ],
    terms: [
      "Bitte  überweisen  Sie  den  Rechnungsbetrag  mit  Angabe  der  Rechnungsnummer  bis  zum  10. des Monats auf das unten angeführte Konto.",
    ],
    deliveryText: ["Vielen Dank für Ihr Vertrauen. Wir liefern Ihnen folgende Waren:"],
    deliveryTerms: ["Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum."],
    orderText: [""],
    reminderText: [""],
    reminderDate: [""],
    paymentTarget: [""],
    paymentTarget2: [""],
    warningText: [""],
    warningDate: [""],
    warningText2: [""],
    warningDate2: [""],
    products: this.formBuilder.array([this.createServiceFormGroup()]),
    bookProducts: [true],
    status: [0],
    locked: [false],
    storno: [""],
    editor: [""],
    editormail: [""]
  });

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private dcs: DataCacheService,
    private lang: TranslationService,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private titleService: Title
  ) {
    if (this.customerNumber) {
      this.inputData.get("customerNumber").patchValue(this.customerNumber);
      this.loadCustomerWithNumber()
    }
  }

  ngOnInit(): void {
    this.doChecks();
  }

  companyDetails = JSON.parse(this.dcs.load("details"));
  email = this.dcs.load("mail");
  isLoading = false;
  isGenerating = false;

  //#region General Functions
  hasPermission(permissionString): boolean {
    const perms = JSON.parse(this.dcs.load("permissions"));
    if (permissionString == 'business' || permissionString == 'enterprise'){
      return perms[permissionString];
    }else{
      const parts = permissionString.split("-");

      if (parts.length == 4) {
        return perms[parts[0]][parts[1]][parts[2]][parts[3]];
      } else if (parts.length == 3) {
        return perms[parts[0]][parts[1]][parts[2]];
      } else if (parts.length == 2) {
        return perms[parts[0]][parts[1]];
      }
    }
  }

  goBack() {
    this.mode = "view";
    if (this.type == 'offers') {
      this.navigate('/buchhaltung/angebote', 0);
    } else if (this.type == 'revenues') {
      if (this.mode == 'view') {
        this.navigate('/buchhaltung/einnahmen', 0);
      } else {
        if (this.number) {
          this.navigate('/buchhaltung/einnahmen', this.number);
        } else {
          this.navigate('/buchhaltung/einnahmen', 0);
        }
      }
    } else if (this.type == 'orders') {
      if (this.inputData.get('orderNumber').value) {
        this.navigate('/aufträge', this.inputData.get('orderNumber').value);
      } else {
        this.navigate('/aufträge', 0);
      }
    } else if (this.type == 'deliverynotes') {
      if (this.inputData.get('orderNumber').value) {
        this.navigate('/aufträge', this.inputData.get('orderNumber').value);
      } else {
        this.navigate('/warenwirtschaft/lieferscheine', 0);
      }
    } else if (this.type == 'paymentreminders') {
      this.navigate('/buchhaltung/zahlungserinnerungen', 0);
    } else if (this.type == 'overduewarnings') {
      this.navigate('/buchhaltung/mahnungen', 0);
    }
  }

  cancel() {
    if (this.mode == 'create') {
      if (this.type == 'offers') {
        this.navigate('/buchhaltung/angebote', 0);
      } else if (this.type == 'revenues') {
        this.navigate('/buchhaltung/einnahmen', 0);
      } else if (this.type == 'orders') {
        this.navigate('/aufträge', 0);
      } else if (this.type == 'deliverynotes') {
        this.navigate('/warenwirtschaft/lieferscheine', 0);
      } else if (this.type == 'paymentreminders') {
        this.navigate('/buchhaltung/einnahmen', this.inputData.get('revenueNumber').value);
      } else if (this.type == 'overduewarnings') {
        this.navigate('/buchhaltung/einnahmen', this.inputData.get('revenueNumber').value);
      }
    }

    this.mode = 'view';
  }

  private doChecks() {
    let perm;
    let perm2 = "erp-crm-customers-read";

    if (this.router.url.indexOf('erstellen') > -1) {
      this.mode = 'create';
    }

    if (this.type == 'orders') {
      if (this.mode == 'view') {
        perm = 'erp-orders-orderconfirmation-read';
      } else if (this.mode == 'edit') {
        perm = 'erp-orders-orderconfirmation-edit';
      } else if (this.mode == 'create') {
        perm = 'erp-orders-orderconfirmation-create';
      } else if (this.mode == 'create') {
        perm = 'erp-orders-orderconfirmation-create';
      }
    } else {
      if (this.type == 'deliverynotes') {
        perm2 = "erp-enterprise";
        if (this.mode == 'view') {
          perm = 'erp-inventory-' + this.type + '-read';
        } else if (this.mode == 'edit') {
          perm = 'erp-inventory-' + this.type + '-edit';
        } else if (this.mode == 'create') {
          perm = 'erp-inventory-' + this.type + '-create';
        }
      } else {
        if (this.mode == 'view') {
          perm = 'erp-accounting-' + this.type + '-read';
        } else if (this.mode == 'edit') {
          perm = 'erp-accounting-' + this.type + '-edit';
        } else if (this.mode == 'create') {
          perm = 'erp-accounting-' + this.type + '-create';
        }

        if (this.type == 'paymentreminders' || this.type == 'overduewarnings') {
          perm2 = "erp-enterprise";
        }
      }
    }

    if (this.hasPermission(perm) && this.hasPermission(perm2)) {
      if (this.mode == "view" || this.mode == "edit" || this.mode == "loading") {
        if (this.number != "" && this.number != null) {
          this.loadDataFromAPI();
        }
      } else if (this.mode == "create") {
        if (this.type == "orders") {
          this.loadDataFromAPI();
          this.mode = "create";
        } else if (this.type == "revenues") {
          if (this.orderNumber != null) {
            this.loadDataFromAPI();
          }
        } else if (this.type == "deliverynotes") {
          if (this.orderNumber) {
            this.loadDataFromAPI();
          }
        } else if (this.type == "paymentreminders") {
          if (this.number && this.mode == 'create') {
            this.loadDataFromAPI();
          }
        } else if (this.type == "overduewarnings") {
          if (this.number && this.mode == 'create') {
            this.loadDataFromAPI();
          }
        }
      } else {
        console.warn("No mode!!!");
      }
    } else {
      this.navigate('/dashboard', 0);
    }
  }

  getTypeName() {
    let lang = this.dcs.load("lang");
    if (this.type == "offers") {
      if (lang == 'de') {
        return "Angebot";
      } else {
        return "Offer";
      }
    } else if (this.type == "revenues") {
      if (lang == 'de') {
        return "Rechnung";
      } else {
        return "Revenue";
      }
    } else if (this.type == "orders") {
      if (lang == 'de') {
        return "Auftrag";
      } else {
        return "Order";
      }
    } else if (this.type == "deliverynotes") {
      if (lang == 'de') {
        return "Lieferschein";
      } else {
        return "Deliverynote";
      }
    }
  }

  getEtitorName() {
    let details = JSON.parse(this.dcs.load("details"));

    return details["name"];
  }

  formatDateReadable(date: string) {
    let d = date.split('-');
    return d[2] + '.' + d[1] + '.' + d[0];
  }

  toggleMode(mode: string) {
    this.mode = mode;
    if(this.mode == "edit"){
      if(this.type == "revenues"){
        this.inputData.get("date").disable();
        this.inputData.get("tax").disable();
        this.inputData.get("skonto").disable();
        this.inputData.get("skontotage").disable();

        let products = this.inputData.get("products") as FormArray;

        //Leistungen aktualisieren
        for (let i = 0; i < products.length; i++) {
          let product = products.at(i-1) as FormGroup;
          product.get("type")?.disable();
          product.get("count")?.disable();
          product.get("product")?.disable();
          product.get("price")?.disable();
          product.get("tax")?.disable();
          product.get("discountType")?.disable();
          product.get("discountValue")?.disable();
        }
      }
    }
  }

  translate(str: string) {
    return this.lang.translate(str);
  }

  navigate(url: string, id: any) {
    if (id == 0) {
      this.router.navigate([url]);
    } else {
      this.router.navigate([url + "/" + id]);
    }
  }

  getTodayPlusXDays(days: number) {
    let date = new Date();
    date.setDate(date.getDate() + days);
    return formatDate(date, 'yyyy-MM-dd', 'de');
  }

  getToday() {
    return formatDate(new Date(), 'yyyy-MM-dd', 'de');
  }

  dateOnlyNumbers(date: string) {
    return formatDate(date, 'yyyyMMdd', 'de');
  }

  removeEmptyFiels() {
    Object.keys(this.inputData.controls).forEach(key => {
      var c = this.inputData.controls[key];
      if (c.value == "") {
        this.inputData.removeControl(c);
      }
    });
  }

  updateSkonto() {
    this.skonto = this.inputData.get('skonto')?.value;
    this.cdr.markForCheck();
  }

  setOrderDefaultText(offerNumber, offerDate) {
    let salutation = this.inputData.get('salutation')?.value + "";
    let forSale = this.inputData.get('forSale')?.value + "";
    let l = this.inputData.get("language")?.value;

    let txt = "";

    if (salutation == "Keine" || salutation == '' || salutation == null) {
      if (l == "de") {
        txt = 'Sehr geehrte Damen und Herren,\n\nwir bedanken uns für Ihr Vertrauen und die Gelegenheit, für Sie tätig zu werden. Gemäß unserem Angebot ' + offerNumber + ' vom ' + this.formatDateReadable(offerDate) + ' freuen wir uns, Ihnen die folgenden Dienstleistungen zu erbringen:';
      } else {
        txt = 'Dear Sir or Madam,\n\nthank you for your trust and the opportunity to work for you. According to our offer ' + offerNumber + ' from ' + this.formatDateReadable(offerDate) + ' we are pleased to provide you with the following services:';
      }
    } else {
      let anrede = "";
      let anredeEN = "";

      if (forSale.includes(' ')) {
        let prt = forSale.split(" ");

        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + prt[1];
          anredeEN = "Dear Sir " + prt[1];
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + prt[1];
          anredeEN = "Dear Madam " + prt[1];
        }
      } else {
        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + forSale;
          anredeEN = "Dear Sir " + forSale;
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + forSale;
          anredeEN = "Dear Madam " + forSale;
        }
      }

      if (l == "de") {
        txt = anrede + ',\n\nwir bedanken uns für Ihr Vertrauen und die Gelegenheit, für Sie tätig zu werden. Gemäß unserem Angebot ' + offerNumber + ' vom ' + this.formatDateReadable(offerDate) + ' freuen wir uns, Ihnen die folgenden Dienstleistungen zu erbringen:';
      } else {
        txt = anredeEN + ',\n\nthank you for your trust and the opportunity to work for you. According to our offer ' + offerNumber + ' from ' + this.formatDateReadable(offerDate) + ' we are pleased to provide you with the following services:';
      }
    }

    this.inputData.get("orderText").patchValue(txt);
  }

  setReminderDefaultText(revenueNumber, revenueDate) {
    let salutation = this.inputData.get('salutation')?.value + "";
    let forSale = this.inputData.get('forSale')?.value + "";
    let l = this.inputData.get("language")?.value;

    let paymentTarget = this.formatDateReadable(this.inputData.get("paymentTarget")?.value);

    let txt = "";

    if (salutation == "Keine" || salutation == '' || salutation == null) {
      if (l == "de") {
        txt = 'Sehr geehrte Damen und Herren,\n\nim hektischen Alltag geht Manches auch mal unter. Deshalb möchten wir Sie heute an unsere noch ausstehende Rechnung ' + revenueNumber + ' vom ' + this.formatDateReadable(revenueDate) + ' über den Betrag von ' + this.getNumberReadable(this.getBrutto()) + '€ erinnern.\n\nBitte begleichen Sie den Rechnungsbetrag bis zum ' + paymentTarget + ' auf unser unten genanntes Konto.\n\nBei Rückfragen können Sie sich jederzeit gerne an uns wenden.';
      } else {
        txt = 'Dear Sir or Madam,\n\nin the hectic everyday life some things get lost. Therefore, we would like to remind you today of our outstanding invoice ' + revenueNumber + ' from ' + this.formatDateReadable(revenueDate) + ' for the amount of ' + this.getNumberReadable(this.getBrutto()) + '€.\n\nPlease pay the invoice amount until ' + paymentTarget + ' to our account mentioned below.\n\nIf you have any questions, please feel free to contact us at any time.';
      }
    } else {
      let anrede = "";
      let anredeEN = "";

      if (forSale.includes(' ')) {
        let prt = forSale.split(" ");

        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + prt[1];
          anredeEN = "Dear Sir " + prt[1];
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + prt[1];
          anredeEN = "Dear Madam " + prt[1];
        }
      } else {
        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + forSale;
          anredeEN = "Dear Sir " + forSale;
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + forSale;
          anredeEN = "Dear Madam " + forSale;
        }
      }

      if (l == "de") {
        txt = anrede + ',\n\nim hektischen Alltag geht Manches auch mal unter. Deshalb möchten wir Sie heute an unsere noch ausstehende Rechnung ' + revenueNumber + ' vom ' + this.formatDateReadable(revenueDate) + ' über den Betrag von ' + this.getBrutto() + '€ erinnern.\n\nBitte begleichen Sie den Rechnungsbetrag bis zum ' + paymentTarget + ' auf unser unten genanntes Konto.\n\nBei Rückfragen können Sie sich jederzeit gerne an uns wenden.';
      } else {
        txt = anredeEN + 'in the hectic everyday life some things get lost. Therefore, we would like to remind you today of our outstanding invoice ' + revenueNumber + ' from ' + this.formatDateReadable(revenueDate) + ' for the amount of ' + this.getBrutto() + '€.\n\nPlease pay the invoice amount until ' + paymentTarget + ' to our account mentioned below.\n\nIf you have any questions, please feel free to contact us at any time.';
      }
    }

    this.inputData.get("reminderText").patchValue(txt);
  }

  setWarningDefaultText() {
    //https://www.ionos.at/startupguide/fileadmin/StartupGuide/Screenshots/mahnung-vorlage-erste-schreiben.png
    //https://www.ionos.at/startupguide/fileadmin/StartupGuide/Screenshots/mahnung-vorlage-zweite-schreiben.png
    //https://www.ionos.at/startupguide/fileadmin/StartupGuide/Screenshots/mahnung-vorlage-dritte-schreiben.png
    let salutation = this.inputData.get('salutation')?.value + "";
    let forSale = this.inputData.get('forSale')?.value + "";
    let l = this.inputData.get("language")?.value;

    let paymentTarget = formatDate(this.inputData.get('paymentTarget').value, 'dd.MM.yyyy', 'de');

    let txt = "";

    if (salutation == "Keine" || salutation == '' || salutation == null) {
      if (l == "de") {
        txt = 'Sehr geehrte Damen und Herren,\n\nauf unsere Zahlungserinnerung vom ' + this.formatDateReadable(this.inputData.get('reminderDate')?.value) + ' haben Sie nicht reagiert. Wir bitten Sie daher, den überfälligen Betrag in Höhe von ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€ bis zum ' + paymentTarget + ' auf das untenstehende Bankkonto zu überweisen.\n\nSollten Sie diesen Zahlungstermin nicht einhalten, werden wir Ihnen Verzugszinsen und Mahnkosten berechnen.\n\nSofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten.';
      } else {
        txt = 'Dear Sir or Madam,\n\nYou have not responded to our payment reminder of ' + this.formatDateReadable(this.inputData.get('reminderDate')?.value) + '. We therefore ask you to transfer the overdue amount of ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€ to the bank account below by ' + paymentTarget + '.\n\n\nIf you do not meet this payment deadline, we will charge you default interest and reminder costs.\n\nIf you have made the payment in the meantime, we ask you to consider this letter as irrelevant.';
      }
    } else {
      let anrede = "";
      let anredeEN = "";

      if (forSale.includes(' ')) {
        let prt = forSale.split(" ");

        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + prt[1];
          anredeEN = "Dear Sir " + prt[1];
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + prt[1];
          anredeEN = "Dear Madam " + prt[1];
        }
      } else {
        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + forSale;
          anredeEN = "Dear Sir " + forSale;
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + forSale;
          anredeEN = "Dear Madam " + forSale;
        }
      }

      if (l == "de") {
        txt = anrede + ',\n\nauf unsere Zahlungserinnerung vom ' + this.formatDateReadable(this.inputData.get('reminderDate')?.value) + ' haben Sie nicht reagiert. Wir bitten Sie daher, den überfälligen Betrag in Höhe von ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€ bis zum ' + paymentTarget + ' auf das untenstehende Bankkonto zu überweisen.\n\nSollten Sie diesen Zahlungstermin nicht einhalten, werden wir Ihnen Verzugszinsen und Mahnkosten berechnen.\n\nSofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten.';
      } else {
        txt = anredeEN + 'You have not responded to our payment reminder of ' + this.formatDateReadable(this.inputData.get('reminderDate')?.value) + '. We therefore ask you to transfer the overdue amount of ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€ to the bank account below by ' + paymentTarget + '.\n\n\nIf you do not meet this payment deadline, we will charge you default interest and reminder costs.\n\nIf you have made the payment in the meantime, we ask you to consider this letter as irrelevant.';
      }
    }

    this.inputData.get("warningText").patchValue(txt);
  }

  setWarningDefaultText2() {
    if (!this.inputData.get("paymentTarget2").value) {
      this.inputData.get("paymentTarget2").patchValue(this.getTodayPlusXDays(7));
    }

    let salutation = this.inputData.get('salutation')?.value + "";
    let forSale = this.inputData.get('forSale')?.value + "";
    let l = this.inputData.get("language")?.value;

    let txt = "";

    if (salutation == "Keine" || salutation == '' || salutation == null) {
      if (l == "de") {
        txt = 'Sehr geehrte Damen und Herren,\n\naurachten';
      } else {
        txt = 'Dear Sir or Madam,\n\nYouourpaymentremevant';
      }
    } else {
      let anrede = "";
      let anredeEN = "";

      if (forSale.includes(' ')) {
        let prt = forSale.split(" ");

        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + prt[1];
          anredeEN = "Dear Sir " + prt[1];
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + prt[1];
          anredeEN = "Dear Madam " + prt[1];
        }
      } else {
        if (salutation == "Herr") {
          anrede = "Sehr geehrter Herr " + forSale;
          anredeEN = "Dear Sir " + forSale;
        } else if (salutation == "Frau") {
          anrede = "Sehr geehrte Frau " + forSale;
          anredeEN = "Dear Madam " + forSale;
        }
      }

      if (l == "de") {
        txt = anrede + ',\n\nSie haben unsere Rechnung ' + this.inputData.get("revenueNumber").value + ' noch immer nicht bezahlt und auf unsere Mahnung vom ' + this.formatDateReadable(this.inputData.get("warningDate").value) + ' nicht reagiert.\n\nMittlerweile sind zusätzliche Verzugszinsen und Mahngebühren angefallen, sodass sich unsere Forderungen gegen Sie aktuell wie folgt zusammensetzen:\n\nRechnung: ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€\nVerzugszinsen (' + this.overdueInterest + '%): ' + this.getNumberReadable(this.getOverdueInterest(this.inputData.get('overdueInvoiceAmount').value)) + '€\nMahngebühren: ' + this.getNumberReadable(this.overdueFees) + '€\n\nBitte überweisen Sie den Gesamtbetrag in Höhe von ' + this.getNumberReadable(this.getAmountWithFees()) + '€ bis spätestens ' + this.formatDateReadable(this.inputData.get("paymentTarget2").value) + ' auf unser Konto.\nSollte Sie den Termin nicht einhalten, sehen wir uns gezwungen ein Inkassobüro mit der Einziehung des ausstehenden Betrages zu beauftragen.';
      } else {
        txt = anredeEN + '\n\nYou have still not paid our invoice ' + this.inputData.get("revenueNumber").value + ' and have not responded to our reminder dated ' + this.formatDateReadable(this.inputData.get("warningDate").value) + '.\n\nIn the meantime, additional default interest and reminder fees have accrued, so that our claims against you are currently as follows\n\nInvoice: ' + this.getNumberReadable(this.inputData.get('overdueInvoiceAmount').value) + '€\nInterest on arrears(' + this.overdueInterest + '%): ' + this.getNumberReadable(this.getOverdueInterest(this.inputData.get('overdueInvoiceAmount').value)) + '€\nReminder fees: ' + this.getNumberReadable(this.overdueFees) + '€\n\nPlease transfer the total amount of ' + this.getNumberReadable(this.getAmountWithFees()) + '€ to our account by ' + this.formatDateReadable(this.inputData.get("paymentTarget2").value) + ' at the latest.\nIf you do not meet the deadline, we will be forced to instruct a debt collection agency to collect the outstanding amount.';
      }
    }

    this.inputData.get("warningText2").patchValue(txt);
  }

  base64ToBlob(base64: string, contentType: string) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  download(type: string) {
    if (!this.isGenerating){
      this.isGenerating = true;
      let data = { action: 'download' };

      if(type == "revenuescopy"){
        data = { action: 'downloadcopy'};
        type = "revenues";
      }

      this.api.sendPOST("v1", type + '/' + this.number, data).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.mode = 'view';
            this.isGenerating = false;

            //Save file
            const base64Data = response['data']['content'];
            const blob = this.base64ToBlob(base64Data, 'application/pdf');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = response['data']['name'];
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } else {
            this.isGenerating = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  private patchFormData() {
    const data = this.backupData;

    if (this.type == "offers") {
      this.backupNumber = data["offerNumber"];

      this.selectCustomer(data["customer"]);

      this.inputData.get("oid").patchValue(data["oid"]);
      this.inputData.get("orderNumber").patchValue(data["orderNumber"]);
      this.inputData.get("offertext").patchValue(data["text"]);
      this.inputData.get("footnote").patchValue(data["footnote"]);

      this.inputData.get("offerNumber").patchValue(data["offerNumber"]);
      this.inputData.get("date").patchValue(data["date"]);
      this.inputData.get("validTil").patchValue(data["validTil"]);
      this.inputData.get("tax").patchValue(data["tax"]);

      this.inputData.get("editor").patchValue(data["editor"]);
      this.inputData.get("editormail").patchValue(data["editormail"]);

      const products = this.inputData.get("products") as FormArray;

      //Array lerren
      while (products.length != 0) {
        products.removeAt(0);
      }

      //Leistungen einfügen
      data["products"].forEach((element) => {
        if (element.type == "product") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: element.type, disabled: false }),
              count: new FormControl({ value: element.count, disabled: false }),
              product: new FormControl({
                value: element.product,
                disabled: false,
              }),
              description: new FormControl({
                value: element.product.description,
                disabled: false,
              }),
              price: new FormControl({
                value: element.product.price,
                disabled: false,
              }),
              tax: new FormControl({
                value: element.product.tax,
                disabled: false,
              }),
              discountType: new FormControl({
                value: element?.discountType,
                disabled: false,
              }),
              discountValue: new FormControl({
                value: element?.discountValue,
                disabled: false,
              })
            })
          );
        } else if (element.type == "postext") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "postext", disabled: false }),
              value: new FormControl({ value: element.value, disabled: false }),
            })
          );
        } else if (element.type == "pagebreak") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "pagebreak", disabled: false }),
            })
          );
        }
      });

      //META-Title
      if (this.mode == 'create') {
        this.titleService.setTitle("Angebot erstellen | Pocket-ERP");
      } else if (this.mode == 'edit') {
        this.titleService.setTitle("Angebot bearbeiten | Pocket-ERP");
      } else {
        this.titleService.setTitle("Angebot " + data["offerNumber"] + " | Pocket-ERP");
      }
    } else if (this.type == "revenues") {
      if (this.orderNumber && !this.number) {
        //Daten von Auftrag laden
        this.selectCustomer(data["customer"]);

        this.inputData.get("orderNumber").patchValue(data["orderNumber"]);
        this.inputData.get("date").patchValue(data["date"]);

        this.inputData.get("tax").patchValue(data["tax"]);

        this.inputData.get("editor").patchValue(data["editor"]);
        this.inputData.get("editormail").patchValue(data["editormail"]);
      } else {
        //Daten von Rechnung laden
        this.backupNumber = data["revenueNumber"];

        this.selectCustomer(data["customer"]);

        this.inputData.get("revenuetext").patchValue(data["text"]);
        this.inputData.get("terms").patchValue(data["terms"]);

        this.inputData.get("revenueNumber").patchValue(data["revenueNumber"]);
        this.inputData.get("orderNumber").patchValue(data["orderNumber"]);
        this.inputData.get("date").patchValue(data["date"]);

        this.inputData.get("paymentreminderID").patchValue(data["paymentreminderID"]);
        this.inputData.get("reminderDate").patchValue(data["paymentreminderDate"]);

        this.inputData.get("overduewarningID").patchValue(data["overduewarningID"]);
        this.inputData.get("warningDate").patchValue(data["overduewarningDate"]);
        this.inputData.get("warningDate2").patchValue(data["overduewarningDate2"]);

        this.inputData.get("tax").patchValue(data["tax"]);

        this.inputData.get("skonto").patchValue(data["skonto"]);
        this.inputData.get("skontotage").patchValue(data["skonto-tage"]);
        this.skonto = data["skonto"];

        this.inputData.get("type").patchValue(data["type"]);
        this.inputData.get("status").patchValue(data["status"]);

        this.inputData.get("storno").patchValue(data["storno"]);
        this.inputData.get("locked").patchValue(data["locked"]);
        this.inputData.get("payed").patchValue(data["payed"]);

        this.inputData.get("editor").patchValue(data["editor"]);
        this.inputData.get("editormail").patchValue(data["editormail"]);

        if (data["fees"] != 0 || data["interestrate"] != 0) {
          this.overdueFees = data["fees"];
          this.overdueInterest = data["interestrate"];
        }
      }

      const products = this.inputData.get("products") as FormArray;

      //Array lerren
      while (products.length != 0) {
        products.removeAt(0);
      }

      //Leistungen einfügen
      data["products"].forEach((element) => {
        if (element.type == "product") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: element.type, disabled: false }),
              count: new FormControl({ value: element.count, disabled: false }),
              product: new FormControl({
                value: element.product,
                disabled: false,
              }),
              description: new FormControl({
                value: element.product.description,
                disabled: false,
              }),
              price: new FormControl({
                value: element.product.price,
                disabled: false,
              }),
              tax: new FormControl({
                value: element.product.tax,
                disabled: false,
              }),
              discountType: new FormControl({
                value: element?.discountType,
                disabled: false,
              }),
              discountValue: new FormControl({
                value: element?.discountValue,
                disabled: false,
              }),
            })
          );
        } else if (element.type == "postext") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "postext", disabled: false }),
              value: new FormControl({ value: element.value, disabled: false }),
            })
          );
        } else if (element.type == "pagebreak") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "pagebreak", disabled: false }),
            })
          );
        }
      });

      //META-Title
      if(this.mode == 'create'){
        this.titleService.setTitle("Rechnung erstellen | Pocket-ERP");
      }else if(this.mode == 'edit'){
        this.titleService.setTitle("Rechnung bearbeiten | Pocket-ERP");
      }else{
        this.titleService.setTitle("Rechnung " + data["revenueNumber"] + " | Pocket-ERP");
      }
    } else if (this.type == "orders") {
      this.backupNumber = data["orderNumber"];

      this.selectCustomer(data["customer"]);

      this.inputData.get("orderNumber").patchValue(data["orderNumber"]);
      this.inputData.get("offerNumber").patchValue(data["offerNumber"]);
      this.inputData.get("date").patchValue(data["date"]);

      this.inputData.get("tax").patchValue(data["tax"]);

      this.inputData.get("editor").patchValue(data["editor"]);
      this.inputData.get("editormail").patchValue(data["editormail"]);

      if (data['text']) {
        this.inputData.get("orderText").patchValue(data["text"]);
      } else {
        this.setOrderDefaultText(data["offerNumber"], data["date"]);
      }

      const products = this.inputData.get("products") as FormArray;

      //Array lerren
      while (products.length != 0) {
        products.removeAt(0);
      }

      //Leistungen einfügen
      data["products"].forEach((element) => {
        if (element['type'] == "product") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: element?.type, disabled: false }),
              count: new FormControl({ value: element?.count, disabled: false }),
              product: new FormControl({
                value: element?.product,
                disabled: false,
              }),
              description: new FormControl({
                value: element?.product.description,
                disabled: false,
              }),
              price: new FormControl({
                value: element?.product.price,
                disabled: false,
              }),
              tax: new FormControl({
                value: element?.product.tax,
                disabled: false,
              }),
              discountType: new FormControl({
                value: element?.discountType,
                disabled: false,
              }),
              discountValue: new FormControl({
                value: element?.discountValue,
                disabled: false,
              }),
            })
          );
        } else if (element['type'] == "postext") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "postext", disabled: false }),
              value: new FormControl({ value: element?.value, disabled: false }),
            })
          );
        } else if (element['type'] == "pagebreak") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "pagebreak", disabled: false }),
            })
          );
        }
      });

      //META-Title
      if (this.mode == 'create') {
        this.titleService.setTitle("AB erstellen | Pocket-ERP");
      } else if (this.mode == 'edit') {
        this.titleService.setTitle("AB bearbeiten | Pocket-ERP");
      } else {
        this.titleService.setTitle("AB " + data["orderNumber"] + " | Pocket-ERP");
      }
    } else if (this.type == "deliverynotes") {
      this.selectCustomer(data["customer"]);
      this.inputData.get("date").patchValue(data["date"]);
      this.inputData.get("orderNumber").patchValue(data["orderNumber"]);

      this.inputData.get("deliveryText").patchValue(data["text"]);
      this.inputData.get("deliveryTerms").patchValue(data["terms"]);

      this.inputData.get("editor").patchValue(data["editor"]);
      this.inputData.get("editormail").patchValue(data["editormail"]);

      const products = this.inputData.get("products") as FormArray;

      //Array lerren
      while (products.length != 0) {
        products.removeAt(0);
      }

      //Leistungen einfügen
      data["products"].forEach((element) => {
        if (element.type == "product") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: element.type, disabled: false }),
              count: new FormControl({ value: element.count, disabled: false }),
              product: new FormControl({
                value: element.product,
                disabled: false,
              }),
              description: new FormControl({
                value: element.product.description,
                disabled: false,
              })
            })
          );
        } else if (element.type == "postext") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "postext", disabled: false }),
              value: new FormControl({ value: element.value, disabled: false }),
            })
          );
        } else if (element.type == "pagebreak") {
          products.push(
            new FormGroup({
              type: new FormControl({ value: "pagebreak", disabled: false }),
            })
          );
        }
      });

      //META-Title
      if (this.mode == 'create') {
        this.titleService.setTitle("Lieferschein erstellen | Pocket-ERP");
      } else if (this.mode == 'edit') {
        this.titleService.setTitle("Lieferschein bearbeiten | Pocket-ERP");
      } else {
        this.titleService.setTitle("Lieferschein - AB " + data["orderNumber"] + " | Pocket-ERP");
      }
    } else if (this.type == "paymentreminders") {
      //Daten von Rechnung laden
      this.backupNumber = data["revenueNumber"];

      this.selectCustomer(data["customer"]);

      if (this.router.url.indexOf('erstellen') > -1) {
        this.inputData.get("date").patchValue(data['date']);
        this.inputData.get("reminderDate").patchValue(this.getToday());
        this.inputData.get("paymentTarget").patchValue(this.getTodayPlusXDays(7));
      } else {
        this.inputData.get("paymentreminderID").patchValue(data["id"]);
        this.inputData.get("reminderDate").patchValue(data["date"]);
        this.inputData.get("locked").patchValue(data["locked"]);
      }

      this.inputData.get("revenueNumber").patchValue(data["revenueNumber"]);

      this.inputData.get("editor").patchValue(data["editor"]);
      this.inputData.get("editormail").patchValue(data["editormail"]);

      this.inputData.get("tax").patchValue(data["tax"]);

      if (data["products"]) {
        const products = this.inputData.get("products") as FormArray;

        //Array lerren
        while (products.length != 0) {
          products.removeAt(0);
        }

        //Leistungen einfügen
        data["products"].forEach((element) => {
          if (element.type == "product") {
            products.push(
              new FormGroup({
                type: new FormControl({ value: element.type, disabled: false }),
                count: new FormControl({ value: element.count, disabled: false }),
                product: new FormControl({
                  value: element.product,
                  disabled: false,
                }),
                description: new FormControl({
                  value: element.product.description,
                  disabled: false,
                }),
                price: new FormControl({
                  value: element.product.price,
                  disabled: false,
                }),
                tax: new FormControl({
                  value: element.product.tax,
                  disabled: false,
                }),
                discountType: new FormControl({
                  value: element?.discountType,
                  disabled: false,
                }),
                discountValue: new FormControl({
                  value: element?.discountValue,
                  disabled: false,
                }),
              })
            );
          } else if (element.type == "postext") {
            products.push(
              new FormGroup({
                type: new FormControl({ value: "postext", disabled: false }),
                value: new FormControl({ value: element.value, disabled: false }),
              })
            );
          } else if (element.type == "pagebreak") {
            products.push(
              new FormGroup({
                type: new FormControl({ value: "pagebreak", disabled: false }),
              })
            );
          }
        });
      }

      if (data["text"]) {
        this.inputData.get("reminderText").patchValue(data["text"]);
      } else {
        this.setReminderDefaultText(data["revenueNumber"], data["date"]);
      }

      //META-Title
      if (this.mode == 'create') {
        this.titleService.setTitle("Zahlungserinnerung erstellen | Pocket-ERP");
      } else if (this.mode == 'edit') {
        this.titleService.setTitle("Zahlungserinnerung bearbeiten | Pocket-ERP");
      } else {
        this.titleService.setTitle("Zahlungserinnerung zu " + data["revenueNumber"] + " | Pocket-ERP");
      }
    } else if (this.type == "overduewarnings") {
      //Daten von Rechnung laden
      if (this.router.url.indexOf('erstellen') > -1) {
        this.backupNumber = data["revenueNumber"];

        this.selectCustomer(data["customer"]);

        this.inputData.get("revenueNumber").patchValue(data["revenueNumber"]);

        this.inputData.get("date").patchValue(data["date"]);
        this.inputData.get("reminderDate").patchValue(data["paymentreminderDate"]);

        this.inputData.get("warningDate").patchValue(this.getToday());

        let date = new Date();
        date.setDate(date.getDate() + 7);
        this.inputData.get("paymentTarget").patchValue(formatDate(date, 'yyyy-MM-dd', 'de-DE'));


        this.inputData.get("editor").patchValue(data["editor"]);
        this.inputData.get("editormail").patchValue(data["editormail"]);

        this.inputData.get("tax").patchValue(data["tax"]);

        if (data["products"]) {
          const products = this.inputData.get("products") as FormArray;

          //Array lerren
          while (products.length != 0) {
            products.removeAt(0);
          }

          //Leistungen einfügen
          data["products"].forEach((element) => {
            if (element.type == "product") {
              products.push(
                new FormGroup({
                  type: new FormControl({ value: element.type, disabled: false }),
                  count: new FormControl({ value: element.count, disabled: false }),
                  product: new FormControl({
                    value: element.product,
                    disabled: false,
                  }),
                  description: new FormControl({
                    value: element.product.description,
                    disabled: false,
                  }),
                  price: new FormControl({
                    value: element.product.price,
                    disabled: false,
                  }),
                  tax: new FormControl({
                    value: element.product.tax,
                    disabled: false,
                  }),
                  discountType: new FormControl({
                    value: element?.discountType,
                    disabled: false,
                  }),
                  discountValue: new FormControl({
                    value: element?.discountValue,
                    disabled: false,
                  }),
                })
              );
            } else if (element.type == "postext") {
              products.push(
                new FormGroup({
                  type: new FormControl({ value: "postext", disabled: false }),
                  value: new FormControl({ value: element.value, disabled: false }),
                })
              );
            } else if (element.type == "pagebreak") {
              products.push(
                new FormGroup({
                  type: new FormControl({ value: "pagebreak", disabled: false }),
                })
              );
            }
          });
        }

        this.inputData.get('overdueInvoiceAmount').patchValue(this.getBrutto());

        //META-Title
        this.titleService.setTitle("Mahnung erstellen | Pocket-ERP");
      } else {
        this.backupNumber = data["revenueNumber"];
        this.inputData.get("revenueNumber").patchValue(data["revenueNumber"]);

        //Datum aus Rechnungsnummer generieren
        let rawdate = this.backupNumber.substring(0, 8);
        this.inputData.get("date").patchValue(rawdate.substring(6) + '.' + rawdate.substring(4, 6) + '.' + rawdate.substring(0, 4));

        this.selectCustomer(data["customer"]);

        this.inputData.get("warningText").patchValue(data["first_warning"]["text"]);
        this.inputData.get("warningDate").patchValue(data["first_warning"]["date"]);
        this.inputData.get("paymentTarget").patchValue(data["first_warning"]["target"]);

        this.inputData.get("warningText2").patchValue(data["second_warning"]["text"]);
        this.inputData.get("warningDate2").patchValue(data["second_warning"]["date"]);
        this.inputData.get("paymentTarget2").patchValue(data["second_warning"]["target"]);

        this.inputData.get('overdueInvoiceAmount').patchValue(data["amount"]);
        if (data["fees"] != 0 || data["interestrate"] != 0) {
          this.overdueFees = data["fees"];
          this.overdueInterest = data["interestrate"];
        }

        this.inputData.get("locked").patchValue(data["locked"]);

        this.inputData.get("editor").patchValue(data["editor"]);
        this.inputData.get("editormail").patchValue(data["editormail"]);

        let mahnstufe = 1;

        if (data["second_warning"]["date"]) {
          mahnstufe = 2;
        }

        //META-Title
        this.titleService.setTitle(mahnstufe + ". Mahnung zu #" + data["revenueNumber"] + " | Pocket-ERP");
      }
    }

    this.cdr.markForCheck();

    if (this.type == "orders") {
      if (this.router.url.indexOf('ab/erstellen') > -1) {
        this.mode = 'create';
      } else {
        this.mode = 'view';
      }
    } else if (this.type == "deliverynotes") {
      if (this.router.url.indexOf('erstellen') > -1) {
        this.mode = 'create';
        this.inputData.get('deliveryTerms').patchValue('Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.');
        this.inputData.get('deliveryText').patchValue('Vielen Dank für Ihr Vertrauen. Wir liefern Ihnen folgende Waren:');
      } else {
        this.mode = 'view';
      }
    } else if (this.type == "paymentreminders") {
      if (this.router.url.indexOf('erstellen') > -1) {
        this.mode = 'create';
        this.setReminderDefaultText(data["revenueNumber"], data["date"]);
      } else {
        this.mode = 'view';
      }
    } else if (this.type == "overduewarnings") {
      if (this.router.url.indexOf('erstellen') > -1) {
        this.mode = 'create';
        this.inputData.get('reminderDate').patchValue(data["paymentreminderDate"]);
        this.setWarningDefaultText();
      } else {
        this.mode = 'view';
      }
    } else {
      if (this.router.url.indexOf('erstellen') > -1) {
        this.mode = 'create';
      } else {
        this.mode = 'view';
      }
    }
  }

  private loadDataFromAPI() {
    let switchBack = false;
    let switchBackNote = false;
    let switchBackWarning = false;

    if (this.mode == 'create' && this.orderNumber) {
      //Type umstellen auf orders damit die daten von auftrag geladen werden
      if (this.type == 'revenues') {
        this.type = 'orders';
        this.number = this.orderNumber;
        switchBack = true;
      } else if (this.type == 'deliverynotes') {
        this.type = 'orders';
        this.number = this.orderNumber;
        switchBackNote = true;
      }

    }

    if (this.mode == 'create' && this.number) {
      if (this.type == 'paymentreminders') {
        this.type = 'revenues';
        switchBack = true;
      } else if (this.type == 'overduewarnings') {
        this.type = 'revenues';
        switchBackWarning = true;
      }
    }

    this.mode = "loading";
    this.api.sendGET("v1", this.type + "/" + this.number).subscribe(
      (response) => {

        if (this.type == "offers") {
          this.backupData = response["data"];
          this.patchFormData();
        } else if (this.type == "revenues") {
          this.backupData = response["data"];
          if (switchBack) {
            this.type = 'paymentreminders';
          }

          if (switchBackWarning) {
            this.type = 'overduewarnings';
          }
          this.patchFormData();
        } else if (this.type == "orders") {
          this.backupData = response["data"];
          if (switchBack) {
            this.type = 'revenues';
            this.number = undefined;
          } else if (switchBackNote) {
            this.type = 'deliverynotes';
            this.number = undefined;
          }
          this.patchFormData();
        } else if (this.type == "deliverynotes") {
          this.backupData = response["data"];
          this.patchFormData();
        } else if (this.type == "paymentreminders") {
          this.backupData = response["data"];
          this.patchFormData();
        } else if (this.type == "overduewarnings") {
          this.backupData = response["data"];
          this.patchFormData();
        }
      },
      (err) => {
        this.toastr.error(
          err.error["message"][this.dcs.load("lang")],
          this.translate("error") + "!",
          {
            positionClass: "toast-bottom-full-width",
            progressBar: true,
            timeOut: 5000,
          }
        );
      }
    );
  }

  getSkontoText() {
    let raw = this.lang.translateWithProvidedLang('skonto-text', this.inputData.get("language")?.value);
    let one = raw.replace("#[TAGE]", this.inputData.get("skontotage")?.value + '');
    let two = one.replace("#[SKONTO]", this.inputData.get("skonto")?.value + '');

    return two;
  }

  getStatusBadgeText(statuscode: number): string {
    let txt;

    if (statuscode == 0) {
      txt = this.translate('open');
    } else if (statuscode == 1) {
      txt = this.translate('payed') + ' (' + this.inputData.get("payed")?.value + ')';
    } else if (statuscode == 2) {
      txt = this.translate('module-accounting-payment-reminder') + ' (' + this.formatDateReadable(this.inputData.get("reminderDate").value) + ')';
    } else if (statuscode == 3) {
      if (this.inputData.get("warningDate2").value) {
        txt = '2.' + this.translate('module-accounting-overduewarning') + ' (' + this.formatDateReadable(this.inputData.get("warningDate2").value) + ')';
      } else {
        txt = '1.' + this.translate('module-accounting-overduewarning') + ' (' + this.formatDateReadable(this.inputData.get("warningDate").value) + ')';
      }

    } else if (statuscode == 4) {
      txt = this.translate('module-accounting-invoices-canceled');
    } else {
      txt = this.translate('unknown');
    }

    return txt
  }

  setPaymentTargetAndWarningText2Default(date) {
    this.inputData.get("paymentTarget2").patchValue(date);
    this.setWarningDefaultText2();
  }

  generateNumber() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.api.sendGET("v1", this.type + "/latest").subscribe(
        (response) => {
          this.isLoading = false;
          let today = formatDate(new Date(), 'yyyyMMdd', 'de');
          if (this.type == 'offers') {
            this.inputData.get('offerNumber').patchValue(today + response["data"]);
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    } else {
      return;
    }
  }
  //#endregion

  //#region Table Drag&Drop
  moveItemInArray(formArray, fromIndex, toIndex) {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

  get productsArray(): FormArray {
    return this.inputData.get("products") as FormArray;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    this.moveItemInArray(
      this.productsArray,
      event.previousIndex,
      event.currentIndex
    );
  }
  //#endregion

  //#region Erstellen
  submitCreation() {
    this.removeEmptyFiels();

    this.inputData.get("editor").patchValue(this.getEtitorName());
    this.inputData.get("editormail").patchValue(this.email);

    if (this.type == 'orders') {
      this.submitEdit();
    } else {
      if (this.isLoading) {
        return;
      } else {
        this.isLoading = true;

        let data = this.inputData.value;

        this.api.sendPOST("v1", this.type, data).subscribe(
          (response) => {
            if (response["title"] == "Success") {
              this.toastr.success(
                response["message"][this.dcs.load("lang")],
                this.translate("success"),
                {
                  positionClass: "toast-bottom-left",
                  progressBar: true,
                }
              );

              this.mode == "view";

              if (this.type == "offers") {
                this.dcs.offers = [];
                this.router.navigate([
                  "/buchhaltung/angebote/" + response["data"],
                ]);
              } else if (this.type == "revenues") {
                this.dcs.revenues = [];
                this.router.navigate([
                  "/buchhaltung/einnahmen/" + response["data"],
                ]);
              } else if (this.type == "orders") {
                this.dcs.orders = [];
                this.router.navigate([
                  "/aufträge/" + response["data"]["orderNumber"],
                ]);
              } else if (this.type == "deliverynotes") {
                this.dcs.deliverynotes = [];
                this.router.navigate([
                  "/warenwirtschaft/lieferscheine",
                ]);
              } else if (this.type == "paymentreminders") {
                this.dcs.deliverynotes = [];
                this.router.navigate([
                  "/buchhaltung/zahlungserinnerungen/" + response["data"],
                ]);
              } else if (this.type == "overduewarnings") {
                this.dcs.overduewarnings = [];
                this.router.navigate([
                  "/buchhaltung/mahnungen/" + response["data"],
                ]);
              }
            } else {
              this.isLoading = false;
              this.toastr.error(
                response["message"][this.dcs.load("lang")],
                this.translate("error") + "!",
                {
                  positionClass: "toast-bottom-full-width",
                  progressBar: true,
                  timeOut: 5000,
                }
              );
            }
          },
          (err) => {
            this.isLoading = false;
            this.toastr.error(
              err.error["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        );
      }
    }
  }
  //#endregion

  //#region Bearbeiten
  submitEdit() {
    if (this.type == "offers") {
      this.submitOfferChanges();
    } else if (this.type == "revenues") {
      this.submitInvoiceChanges();
    } else if (this.type == "orders") {
      this.submitOrderChanges();
    }
  }

  private submitOfferChanges() {
    if (this.isLoading) {
      return;
    } else {
      this.removeEmptyFiels();

      this.isLoading = true;
      this.api.sendPATCH("v1", "offers", this.inputData.value).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.isLoading = false;

            if (this.backupNumber != this.number) {
              //cache löschen weil Angebotsnummer geändert wurde
              this.dcs.offers = [];
            }

            this.toastr.success(
              response["message"][this.dcs.load("lang")],
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );
            this.mode = "view";
            this.closePopups();
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  private submitInvoiceChanges() {
    if (this.isLoading) {
      return;
    } else {
      this.removeEmptyFiels();

      this.isLoading = true;
      this.api.sendPATCH("v1", "revenues", this.inputData.value).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.isLoading = false;

            if (this.backupNumber != this.number) {
              //cache löschen weil Rechnungsnummer geändert wurde
              this.dcs.revenues = [];
            }

            this.toastr.success(
              response["message"][this.dcs.load("lang")],
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );
            this.mode = "view";
            this.closePopups();
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  private submitOrderChanges() {
    this.backupNumber == this.number;

    if (this.isLoading) {
      return;
    } else {
      this.removeEmptyFiels();

      this.isLoading = true;
      this.api.sendPATCH("v1", "orders", this.inputData.value).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.isLoading = false;

            if (this.backupNumber != this.number) {
              //cache löschen weil Rechnungsnummer geändert wurde
              this.dcs.orders = [];
            }

            this.toastr.success(
              response["message"][this.dcs.load("lang")],
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );
            this.mode = "view";
            this.closePopups();
            this.navigate('/aufträge/ab', this.number);
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }
  //#endregion

  //#region Stornieren&Bezahlt markieren & Mahnstufe erhöhen
  setInvoicePayed() {
    if (this.isLoading) {
      return;
    } else {
      this.isLoading = true;
      var data = {
        revenueNumber: this.number,
        payed: this.payedDate,
        skonto: this.skonto,
        fees: this.overdueFees,
        interestrate: this.overdueInterest,
        locked: 1
      };

      this.api.sendPATCH("v1", "revenues", data).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.isLoading = false;

            this.dcs.revenues = [];

            let msg = '';
            if (this.dcs.load("lang") == 'de') {
              msg = "Rechnung wurde als bezahlt erfasst.";
            } else {
              msg = "Invoice has been set to payed.";
            }

            this.inputData.get('status').patchValue(1);
            this.inputData.get('locked').patchValue(true);
            this.inputData.get('payed').patchValue(this.formatDateReadable(data['payed']));

            this.toastr.success(
              msg,
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );
            this.mode = "view";
            this.closePopups();
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  cancelInvoice() {
    if (this.isLoading) {
      return;
    } else {
      this.isLoading = true;

      let data = {
        revenueNumber: this.inputData.get('revenueNumber').value,
        storno: "generate",
        locked: true,
      };

      this.api.sendPATCH("v1", 'revenues', data).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.inputData.get('locked').patchValue(true);
            this.inputData.get('status').patchValue(6);
            this.inputData.get('storno').patchValue(response['data']);

            this.toastr.success(
              response["message"][this.dcs.load("lang")],
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );

            this.dcs.revenues = [];
            this.router.navigate([
              "/buchhaltung/einnahmen/" + this.inputData.get('revenueNumber').value,
            ]);

            this.closePopups();
            this.mode == "view";
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  upgradeOverduewarning() {
    if (this.isLoading) {
      return;
    } else {
      this.isLoading = true;

      if (!this.inputData.get('warningText2').value) {
        this.setWarningDefaultText2();
      }

      var data = {
        id: this.number,
        warningDate2: this.inputData.get('warningDate2').value,
        warningText2: this.inputData.get('warningText2').value,
        fees: this.overdueFees,
        interestrate: this.overdueInterest,
        paymentTarget: this.inputData.get('paymentTarget2').value
      };

      this.api.sendPATCH("v1", "overduewarnings", data).subscribe(
        (response) => {
          if (response["title"] == "Success") {
            this.isLoading = false;

            this.dcs.overduewarnings = [];

            let msg = '';
            if (this.dcs.load("lang") == 'de') {
              msg = "Mahnstufe wurde erfolgreich erhöht.";
            } else {
              msg = "Dunning level was successfully increased.";
            }

            this.inputData.get('locked').patchValue(true);

            this.toastr.success(
              msg,
              this.translate("success"),
              {
                positionClass: "toast-bottom-left",
                progressBar: true,
              }
            );
            this.mode = "view";
            this.closePopups();
          } else {
            this.isLoading = false;
            this.toastr.error(
              response["message"][this.dcs.load("lang")],
              this.translate("error") + "!",
              {
                positionClass: "toast-bottom-full-width",
                progressBar: true,
                timeOut: 5000,
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }
  //#endregion

  //#region Popups
  openPopup(pop) {
    this.mode = "loading";
    this.modalService.open(pop, { centered: true, backdrop: 'static' });
  }

  closePopups() {
    this.mode = "view";
    this.modalService.dismissAll();
  }

  openWarningPopup(pop) {
    //Zahlungszel von 1. Mahnng zwischenspeichern
    this.tmp = this.inputData.get("paymentTarget2").value;

    //Standartwerte für gebühren
    if (!this.overdueFees) {
      this.overdueFees = 7.5;
    }

    if (!this.overdueInterest) {
      this.overdueInterest = 12.58;
    }

    this.inputData.get("paymentTarget2").patchValue(this.getTodayPlusXDays(5));
    this.inputData.get("warningDate2").patchValue(formatDate(new Date(), 'yyyy-MM-dd', 'de-DE'));
    this.setWarningDefaultText2();

    this.mode = "loading";
    this.modalService.open(pop, { centered: true, backdrop: 'static', size: 'lg' });
  }

  closeWarningPopup() {
    this.inputData.get("warningDate2").patchValue("");
    this.inputData.get("warningText2").patchValue("");
    this.inputData.get("paymentTarget2").patchValue(this.tmp);
    this.tmp = null;
    this.overdueFees = 0;
    this.overdueInterest = 0;

    this.mode = "view";
    this.modalService.dismissAll();
  }
  //#endregion

  //#region Löschen
  deleteDocument() {
    if (this.isLoading) {
      return;
    } else {
      this.isLoading = true;

      this.api.sendDELETE("v1", this.type + "/" + this.number).subscribe(
        (response) => {
          this.toastr.success(
            response["message"][this.dcs.load("lang")],
            this.translate("successful") + "!",
            {
              positionClass: "toast-bottom-left",
              progressBar: true,
            }
          );
          this.closePopups();

          if (this.type == "offers") {
            this.dcs.offers = [];
          } else if (this.type == "orders") {
            this.dcs.orders = [];
          } else if (this.type == "deliverynotes") {
            this.dcs.deliverynotes = [];
          } else if (this.type == "paymentreminders") {
            this.dcs.paymentreminders = [];
          } else if (this.type == "overduewarnings") {
            this.dcs.overduewarnings = [];
          }

          if (this.type == "offers") {
            this.router.navigate(["/buchhaltung/angebote"]);
          } else if (this.type == "orders") {
            this.router.navigate(["/aufträge"]);
          } else if (this.type == "deliverynotes") {
            this.router.navigate(["/warenwirtschaft/lieferscheine"]);
          } else if (this.type == "paymentreminders") {
            let num = this.inputData.get('revenueNumber').value;
            if (num) {
              this.router.navigate(["/buchhaltung/einnahmen/" + num]);
            } else {
              this.router.navigate(["/buchhaltung/zahlungserinnerungen"]);
            }
          } else if (this.type == "overduewarnings") {
            let num = this.inputData.get('revenueNumber').value;
            if (num) {
              this.router.navigate(["/buchhaltung/einnahmen/" + num]);
            } else {
              this.router.navigate(["/buchhaltung/mahnungen"]);
            }
          }
        },
        (err) => {
          this.isLoading = false;

          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }
  //#endregion

  //#region Header&Footer von Dokumenten
  headerContent(val: string) {
    const documents = JSON.parse(this.companyDetails["documents"]);
    const header = documents["header"];

    return header[val];
  }

  headerAlignment(val: string) {
    const documents = JSON.parse(this.companyDetails["documents"]);
    const header = documents["header"];
    const alignment = header["alignment"];
    if (alignment[val] == "L") {
      return "float-left text-left";
    } else {
      return "float-right text-right";
    }
  }

  footerContent(val: string) {
    const documents = JSON.parse(this.companyDetails["documents"]);
    const footer = documents["footer"];

    return footer[val];
  }

  footerAlignment(val: string) {
    const documents = JSON.parse(this.companyDetails["documents"]);
    const header = documents["footer"];
    const alignment = header["alignment"];
    if (alignment[val] == "L") {
      return "float-left text-left";
    } else if (alignment[val] == "R") {
      return "float-right text-right";
    } else {
      return "float-center text-center";
    }
  }

  spacerColor() {
    const documents = JSON.parse(this.companyDetails["documents"]);
    const spacerData = documents["spacer"];
    const enabled = spacerData && spacerData["enabled"] !== undefined ? spacerData["enabled"] : false;
    const spacerColor = spacerData && spacerData["color"] ? spacerData["color"] : '#000000';

    if (enabled) {
      return {
        'border-top': `2px solid ${spacerColor}`
      };
    } else {
      return {
        'border-top': '0px'
      };
    }
  }
  //#endregion

  //#region Calculation
  getNumberReadable(num: number): string {
    let n = Number(num).toFixed(2).toLocaleString();
    return n.replace('.', ',');
  }

  getTotal(): number {
    const products = this.inputData.get("products")?.getRawValue();
    let total = 0;

    for (const items of products) {
      if (items.type == "product") {
        let sum = items.count * items.product.price;
        total = total + sum;
      }
    }

    return Number(total.toFixed(2));
  }

  getDiscount(): number {
    const products = this.inputData.get("products")?.getRawValue();
    let total = 0;

    for (const items of products) {
      if (items.discountValue > 0 && items.type == "product") {
        if (items.discountType == "%") {
          total =
            total + ((items.count * items.product.price) / 100) * items.discountValue;
        } else {
          total = total + items.discountValue;
        }
      }
    }

    return Number(total.toFixed(2));
  }

  getNetto(): number {
    let netto = this.getTotal() - this.getDiscount();
    return Number(netto.toFixed(2));
  }

  calcWithSkonto(sum): number {
    let skonto = (sum / 100) * this.skonto;
    let total = sum - skonto;
    return Number(total.toFixed(2));
  }

  getTax(): number {
    const products = this.inputData.get("products")?.getRawValue();
    let total = 0;
    const hasTax = this.inputData.get("tax")?.getRawValue();

    for (const items of products) {
      if (items.type == "product") {
        if (hasTax) {
          let zs = items.count * items.price;
          let dsc = this.getDiscountForItem(items.price, items.count, items.discountValue, items.discountType);
          let net = zs - dsc;
          let tax = (net / 100) * items.tax;

          total = total + tax;
        } else {
          total = 0;
        }
      }
    }

    return Number(total.toFixed(2));
  }

  getIncludedTaxes() {
    var inData = this.inputData.get("products")?.getRawValue();
    var taxArr = [];

    //Group by taxrate
    for (const product of inData) {
      if (product.type == "product") {
        var rate = product.tax;
        if (taxArr[rate]) {
          taxArr[rate] =
            taxArr[rate] +
            (this.getNettoForItem(
              product.price, product.count,
              product.discountValue,
              product.discountType
            ) /
              100) *
            product.tax;
        } else {
          taxArr[rate] =
            (this.getNettoForItem(
              product.price, product.count,
              product.discountValue,
              product.discountType
            ) /
              100) *
            product.tax;
        }
      }
    }

    return taxArr;
  }

  getBrutto(): number {
    const total = Number(this.getNetto() + this.getTax());
    return Number(total.toFixed(2));
  }

  getDiscountForItem(
    price: number,
    count: number,
    discount: number,
    discountType: string
  ) {
    var posTotal = price * count;
    var dc = 0;

    if (discountType == "%") {
      dc = (posTotal / 100) * discount;
    } else {
      dc = discount;
    }

    return dc;
  }

  getNettoForItem(price, count, dc, dcType) {
    return (price * count) - this.getDiscountForItem(price, count, dc, dcType);
  }

  getTaxForItem(netto, taxRate): number {
    let tax = 0;
    const hasTax = this.inputData.get("tax")?.getRawValue();

    if (hasTax) {
      tax = (netto / 100) * taxRate;
    }

    return tax;
  }

  getBruttoForItem(
    price: number,
    count: number,
    discount: number,
    discountType: string,
    tax: number
  ): number {
    var posTotal = price * count;
    var dc = 0;

    if (discountType == "%") {
      dc = (posTotal / 100) * discount;
    } else {
      dc = discount;
    }

    var net = posTotal - dc;
    var tax = (net / 100) * tax;

    if (this.inputData.get("tax")?.getRawValue()) {
      return net + tax;
    } else {
      return net;
    }
  }

  getOverdueInterest(net): number {
    let rate = 0;

    if (this.overdueInterest > 0) {
      let precent = Number(this.overdueInterest);
      rate = (net / 100) * precent;
    }

    return Number(rate.toFixed(2));
  }

  getBruttoWithFees() {
    let s = this.getBrutto();
    let interest = this.getOverdueInterest(s);
    let f = Number(this.overdueFees);

    return Number(s + interest + f).toFixed(2);
  }

  getAmountWithFees(): number {
    let s = this.inputData.get('overdueInvoiceAmount').getRawValue();
    let interest = this.getOverdueInterest(s);
    let f = Number(this.overdueFees);

    return Number(s + interest + f);
  }
  //#endregion

  //#region Produkte
  public productsList = [];
  public filter = "";
  public productSelectOpen = false;

  setFilter(searchValue: string) {
    this.filter = searchValue;
    this.productSelectOpen = false;
  }

  filterProducts() {
    var endpoint = "products/name-description/" + this.filter;
    this.api.sendGET("v1", endpoint).subscribe(
      (response) => {
        this.productsList = [];
        this.filter = "";

        this.productsList = response["data"];
        this.productSelectOpen = true;
      },
      (err) => {
        this.toastr.error(
          err.error["message"][this.dcs.load("lang")],
          this.translate("error") + "!",
          {
            positionClass: "toast-bottom-full-width",
            progressBar: true,
            timeOut: 5000,
          }
        );
      }
    );
  }

  handleDesctiptionChange($event, id) {
    let f = <FormGroup>this.inputData;
    let fData = f.get("products")?.getRawValue();

    const product = <FormControl>fData[id];
    const p = <FormGroup>product["product"];

    p["description"] = product["description"];
  }

  updateProductValues($event, id) {
    this.productSelectOpen = false;

    let price = $event["price"];
    let productNumber = $event["productNumber"];
    let tax = $event["tax"];
    let desc = $event["description"];

    let p = <FormGroup>this.inputData;
    const products = (<FormArray>p.get("products")).at(id);

    products.patchValue({
      price: price,
      tax: tax,
      description: desc,
      productNumber: productNumber,
    });
  }

  public addServiceFormGroup() {
    let p = <FormGroup>this.inputData;
    const products = p.get("products") as FormArray;
    products.push(this.createServiceFormGroup());
  }

  public removeOrClearService(i: number) {
    let p = <FormGroup>this.inputData;
    const products = p.get("products") as FormArray;
    products.removeAt(i);
  }

  private createServiceFormGroup(): FormGroup {
    return new FormGroup({
      type: new FormControl("product"),
      count: new FormControl(1),
      product: new FormControl(""),
      productNumber: new FormControl(""),
      description: new FormControl(""),
      price: new FormControl(""),
      tax: new FormControl(""),
      discountType: new FormControl("€"),
      discountValue: new FormControl(0),
    });
  }

  addPageBreak() {
    let p = <FormGroup>this.inputData;
    const products = p.get("products") as FormArray;

    let data = new FormGroup({
      type: new FormControl("pagebreak"),
    });
    products.push(data);
  }

  addPositionText(text: string) {
    let p = <FormGroup>this.inputData;
    const products = p.get("products") as FormArray;

    let data = new FormGroup({
      type: new FormControl("postext"),
      value: new FormControl(text),
    });
    products.push(data);
  }

  getPos(i: number): number {
    const products = this.inputData.get("products")?.getRawValue();
    let res = 0;

    for (let x = 0; x < i; x++) {
      if (products[x]["count"] > 0) {
      } else {
        res++;
      }
    }

    return i - res;
  }
  //#endregion

  //#region Customer
  public customerList = [];
  public filterCustomer = "";
  showFilterCustomerError = false;

  setFilterCustomers(val) {
    this.filterCustomer = val;
    
    if(val == ""){
      this.showFilterCustomerError = true;
    }else{
      this.showFilterCustomerError = false;
    }
  }

  selectCustomer(e: any) {
    this.setCustomerValues(e);
  }

  private setCustomerValues(cus: any) {
    let c = <FormGroup>this.inputData;
    c.get("customerNumber").patchValue(cus.customerNumber);
    c.get("name").patchValue(cus.name);
    c.get("salutation").patchValue(cus.salutation);
    c.get("forSale").patchValue(cus.forSale);
    c.get("adress").patchValue(cus.adress);
    c.get("delivery").patchValue(cus.delivery);
    c.get("language").patchValue(cus.language);
    c.get("uid").patchValue(cus?.uid);

    c.get("skonto").patchValue(cus.skonto);
    c.get("skontotage").patchValue(cus.skontotage);
    this.skonto = cus.skonto;

    if (cus.revenues) {
      c.get("revenuetext").patchValue(cus.revenues.text);
      c.get("terms").patchValue(cus.revenues.terms);
    }

    if (cus.offers) {
      c.get("offertext").patchValue(cus["offers"]["text"]);
      c.get("footnote").patchValue(cus["offers"]["footnote"]);
    }

    if (cus.deliverynotes) {
      c.get("deliveryText").patchValue(cus["deliverynotes"]["text"]);
      c.get("deliveryTerms").patchValue(cus["deliverynotes"]["terms"]);
    }

    if (!c.get('adress.street')?.value) {
      //this.doChecks();
    } else {
      console.log(c.get('adress.street')?.value);
    }
  }

  filterCustomers() {
    if(this.filterCustomer == ""){
      this.showFilterCustomerError = true;
    }else{
      this.showFilterCustomerError = false;
      this.api.sendGET("v1", "customers/name/" + this.filterCustomer).subscribe(
        (response) => {
          this.customerList = response["data"];
          this.filterCustomer = "";
        },
        (err) => {
          this.toastr.error(
            err.error["message"][this.dcs.load("lang")],
            this.translate("error") + "!",
            {
              positionClass: "toast-bottom-full-width",
              progressBar: true,
              timeOut: 5000,
            }
          );
        }
      );
    }
  }

  loadCustomerWithNumber() {
    this.api.sendGET("v1", "customers/" + this.customerNumber).subscribe(
      (response) => {
        this.selectCustomer(response["data"]);
      },
      (err) => {
        this.toastr.error(
          err.error["message"][this.dcs.load("lang")],
          this.translate("error") + "!",
          {
            positionClass: "toast-bottom-full-width",
            progressBar: true,
            timeOut: 5000,
          }
        );
      }
    );
  }
  //#endregion
}
