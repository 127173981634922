<div class="ticker-wrapper {{ config?.layout.variant }} {{transparentBGClass}}"
  *ngIf="this.config?.layout.news === true"
  [ngClass]="menuPosition === 'Side' ? (this.isCol() ? 'space-left-compact' : (config?.layout.sidebar.size == 'sidebar-sm' ? 'space-left-small' : (config?.layout.sidebar.size == 'sidebar-md' ? 'space-left' : (config?.layout.sidebar.size == 'sidebar-lg' ? 'space-left-large' : 'space-left-compact')))):''">
  <div class="heading">{{ this.translate('design-news') }}</div>

  <ul class="news-ticker">
    <li *ngFor="let news of this.newsData">{{ news }}</li>
  </ul>
</div>

<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="this.config?.layout.news === true ? 'space-top' : {'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div id="cypress-menuToggle"
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:"
            (click)="toggleSearchOpenClass(true)"><i class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text"
              [placeholder]="this.translate('search-placeholder')" tabindex="0" autofocus data-search="template-search"
              [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()"
              (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name"
                    (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">{{ this.translate('search-noresult')
                    }}</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/dashboard']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Pocket-ERP logo"
                      [src]="config?.layout.overrideLogo ? this.userDetails?.logo : logoUrl"></div>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- Sprache -->
          <li class="nav-item d-none d-inline-block" display="none" ngbDropdown>
            <a class="nav-link" id="dropdown-flag" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle
              [ngbTooltip]="this.translate('language')" placement="bottom"><i class="ft-globe font-medium-3"></i></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="changeAndReload('de')"><img
                  class="langimg mr-2" src="./assets/img/flags/at.png" alt="flag" /><span class="font-small-3">Deutsch
                  <i class="ft-check" *ngIf="this.getCurrentLang() == 'de'"></i></span></a>
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="changeAndReload('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span class="font-small-3">English
                  <i class="ft-check" *ngIf="this.getCurrentLang() == 'en'"></i></span></a>
            </div>
          </li>

          <!-- Icons -->
          <li class="nav-item d-none d-inline-block mr-2">
            <help-tour></help-tour>
          </li>


          <!-- User -->
          <li class="dropdown nav-item mr-1 bl" placement="bottom-left" display="static" id="cypress-sidebar">
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
              (click)="toggleNotificationSidebar();">
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{ userDetails['name'] }}</span>
                <span class="text-right text-muted font-small-3">{{ userDetails['company_name'] ? userDetails['company_name']:'&nbsp;' }}</span>
              </div>
              <img class="avatar" [src]="'https://api.pocket-systems.com/avatar/' + this.avatar()" alt="avatar"
                height="35" width="35" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>