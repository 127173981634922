
export const LISTITEMS: any[] = [
    { "url": "/dashboard", "name": "Dashboard", "icon": "ft-home" },

    { "url": "/kundenverwaltung", "name": "Kundenverwaltung", "icon": "ft-users" },
    { "url": "/kundenverwaltung/erstellen", "name": "Kunde anlegen", "icon": "ft-user-plus" },

    { "url": "/aufträge", "name": "Aufträge anzeigen", "icon": "ft-folder" },
    { "url": "/aufträge/erstellen", "name": "Auftrag erstellen", "icon": "ft-folder-plus" },

    { "url": "/buchhaltung", "name": "Buchhaltung", "icon": "ft-book-open" },
    { "url": "/buchhaltung/angebote", "name": "Angebote", "icon": "ft-file" },
    { "url": "/buchhaltung/angebote/erstellen", "name": "Angebot erstellen", "icon": "ft-file" },
    { "url": "/buchhaltung/einnahmen", "name": "Einnahmen", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/einnahmen/erstellen", "name": "Rechnung erstellen", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/einnahmen/sonstige/erstellen", "name": "Sonstige Einnahmen erfassen", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/ausgaben", "name": "Ausgaben", "icon": "ft-file-minus" },
    { "url": "/buchhaltung/ausgaben/erstellen", "name": "Ausgaben erfassen", "icon": "ft-file-minus" },
    { "url": "/buchhaltung/zahlungserinnerungen", "name": "Zahlungserinnerungen", "icon": "ft-info" },
    { "url": "/buchhaltung/zahlungserinnerungen/erstellen", "name": "Zahlungserinnerung erstellen", "icon": "ft-info" },
    { "url": "/buchhaltung/mahnungen", "name": "Mahnungen", "icon": "ft-alert-circle" },

    { "url": "/warenwirtschaft", "name": "Warenwirtschaft", "icon": "ft-archive" },
    { "url": "/warenwirtschaft/artikel", "name": "Artikel & Lagerstand", "icon": "ft-package" },
    { "url": "/warenwirtschaft/artikel/erstellen", "name": "Artikel anlegen", "icon": "ft-package" },
    { "url": "/warenwirtschaft/lieferanten", "name": "Lieferanten", "icon": "ft-truck" },
    { "url": "/warenwirtschaft/lieferanten/erstellen", "name": "Lieferant erstellen", "icon": "ft-truck" },
    { "url": "/warenwirtschaft/lieferscheine", "name": "Lieferscheine", "icon": "ft-file" },
    { "url": "/warenwirtschaft/lieferscheine/erstellen", "name": "Lieferschein erstellen", "icon": "ft-file" },

    { "url": "/support", "name": "Support & Hilfe", "icon": "ft-tag" },
    { "url": "/support/tickets", "name": "Tickets", "icon": "ft-tag" },
    { "url": "/support/helpdesk", "name": "Helpdesk", "icon": "ft-book" },

    { "url": "/dms", "name": "DMS (Dateimanager)", "icon": "ft-folder" },
    { "url": "/admin", "name": "Admin-Bereich", "icon": "ft-settings" }
]


export const LISTITEMS_EN: any[] = [
    { "url": "/dashboard", "name": "Dashboard", "icon": "ft-home" },

    { "url": "/kundenverwaltung", "name": "Customers", "icon": "ft-users" },
    { "url": "/kundenverwaltung/erstellen", "name": "Create customer", "icon": "ft-user-plus" },

    { "url": "/aufträge", "name": "Show orders", "icon": "ft-folder" },
    { "url": "/aufträge/erstellen", "name": "Create order", "icon": "ft-folder-plus" },

    { "url": "/buchhaltung", "name": "accounting", "icon": "ft-book-open" },
    { "url": "/buchhaltung/angebote", "name": "Offers", "icon": "ft-file" },
    { "url": "/buchhaltung/angebote/erstellen", "name": "Create offer", "icon": "ft-file" },
    { "url": "/buchhaltung/einnahmen", "name": "Income", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/einnahmen/erstellen", "name": "Create bill", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/einnahmen/sonstige/erstellen", "name": "Create other bill", "icon": "ft-file-plus" },
    { "url": "/buchhaltung/ausgaben", "name": "Expenses", "icon": "ft-file-minus" },
    { "url": "/buchhaltung/ausgaben/erstellen", "name": "Create expense", "icon": "ft-file-minus" },
    { "url": "/buchhaltung/zahlungserinnerungen", "name": "Paymentreminders", "icon": "ft-info" },
    { "url": "/buchhaltung/zahlungserinnerungen/erstellen", "name": "Create paymentreminder", "icon": "ft-info" },
    { "url": "/buchhaltung/mahnungen", "name": "Overduewarnings", "icon": "ft-alert-circle" },

    { "url": "/warenwirtschaft", "name": "Inventory management", "icon": "ft-archive" },
    { "url": "/warenwirtschaft/artikel", "name": "Products & Stock", "icon": "ft-package" },
    { "url": "/warenwirtschaft/artikel/erstellen", "name": "Create product", "icon": "ft-package" },
    { "url": "/warenwirtschaft/lieferanten", "name": "Suppliers", "icon": "ft-truck" },
    { "url": "/warenwirtschaft/lieferanten/erstellen", "name": "Create supplier", "icon": "ft-truck" },
    { "url": "/warenwirtschaft/lieferscheine", "name": "Deliverynotes", "icon": "ft-file" },
    { "url": "/warenwirtschaft/lieferscheine/erstellen", "name": "Create deliverynote", "icon": "ft-file" },

    { "url": "/support", "name": "Support & Help", "icon": "ft-tag" },
    { "url": "/support/tickets", "name": "Tickets", "icon": "ft-tag" },
    { "url": "/support/helpdesk", "name": "Helpdesk (Only available in german)", "icon": "ft-book" },

    { "url": "/dms", "name": "DMS (Filemanager)", "icon": "ft-folder" },
    { "url": "/admin", "name": "Admin-Area", "icon": "ft-settings" }
]