import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';

import { ApiService } from "../services/api.service";
import { DataCacheService } from "../services/datacache.service";

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class AuthService {

  constructor(
    public router: Router,
    private dcs: DataCacheService,
    private api: ApiService,
    private dds: DeviceDetectorService
  ) {

  }

  getFingerPrint(): Number {
    const deviceInfo = this.dds.getDeviceInfo();

    const osVersion = deviceInfo?.os_version.replace('-', '');
    const fp = this.dds.isDesktop() + '-' + this.dds.isMobile() + '-' + this.dds.isTablet() + '-' + deviceInfo?.browser + '-' + deviceInfo?.browser_version + '-' + deviceInfo?.device + '-' + deviceInfo?.deviceType + '-' + deviceInfo?.orientation + '-' + deviceInfo?.os + '-' + osVersion + '-' + window.innerWidth + 'x' + window.innerHeight;

    return this.getCheckSumForFingerpring(fp);
  }

  checkFingerprintsForMatch(): Boolean {
    const key = this.dcs.load("api-key");

    //Key spliten mit Char, und anschließen letzen eintrag der array laden
    let parts = key.split("-");
    let fp = parts[parts.length - 1];

    if (fp == this.getFingerPrint()) {
      return true;
    } else {
      return false;
    }
  }

  private getCheckSumForFingerpring(str: string): Number {
    const dictionary = { "a": 11, "b": 22, "c": 33, "d": 44, "e": 55, "f": 66, "g": 77, "h": 88, "i": 99, "j": 1010, "k": 1111, "l": 1212, "m": 1313, "n": 1414, "o": 1515, "p": 1616, "q": 1717, "r": 1818, "s": 1919, "t": 2020, "u": 2121, "v": 2222, "w": 2323, "x": 2424, "y": 2525, "z": 2626, "A": 2727, "B": 2828, "C": 2929, "D": 3030, "E": 3131, "F": 3232, "G": 3333, "H": 3434, "I": 3535, "J": 3636, "K": 3737, "L": 3838, "M": 3939, "N": 4040, "O": 4141, "P": 4242, "Q": 4343, "R": 4444, "S": 4545, "T": 4646, "U": 4747, "V": 4848, "W": 4949, "X": 5050, "Y": 5151, "Z": 5252, "-": 6969, ".": 420420 };
    let checksum = 0;

    for (let i = 0; i < str.length; i++) {
      const letter = str[i];
      let numberFromLetter = 0;

      if (Number(letter) || Number(letter) == 0) {
        numberFromLetter = Number(letter);
      } else {
        numberFromLetter = dictionary[letter];
      }

      checksum = checksum + numberFromLetter;
    }

    return checksum;
  }

  logout() {
    this.dcs.clear();
    this.router.navigate(["/"]);
  }

  isAuthenticated() {
    const key = this.dcs.load("api-key");

    if (key === undefined || key == null) {
      this.router.navigate(["/login"]);
      return false;
    } else {
      return this.api.sendAccountGET("account").subscribe(
        (response) => {
          if (
            response["title"] == "Success" &&
            response["message"]["de"] == "API-Schlüssel gültig" &&
            response["message"]["en"] == "API-Key valid"
          ) {
            if (this.checkFingerprintsForMatch()) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        },
        (err) => {
          if (err.error["message"]["de"] == "Ihre Lizenz ist abgelaufen!" && err.error["message"]["en"] == "Your license has expired!") {
            return false
          }
        }
      );
    }
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
