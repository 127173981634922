<!-- Theme customizer Starts-->
<div #customizer class="customizer open">
  <div [perfectScrollbar] class="customizer-content ps-container ps-theme-dark">
    <h4 class="text-uppercase">{{ this.translate('design-title') }}</h4>

    <!-- Newsbar Options Starts-->
    <div class="ct-toggler pr">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-info font-medium-2 mr-2"></i><span>{{
          this.translate('design-news') }}</span></h6>
      <div class="news-switch">
        <div class="radio radio-sm d-inline-block menu-side mr-3">
          <input id="news-show" type="radio" name="news-switch" [checked]="config.layout.news === true"
            (click)="customizerService.toggleNewsbar(true)">
          <label for="news-show">{{ this.translate('show') }}</label>
        </div>
        <div class="radio radio-sm d-inline-block menu-top">
          <input id="news-hide" type="radio" name="news-switch" [checked]="config.layout.news === false"
            (click)="customizerService.toggleNewsbar(false)">
          <label for="news-hide">{{ this.translate('hide') }}</label>
        </div>
      </div>
    </div>

    <!-- Layout Options Starts-->
    <div class="ct-theme pr">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-sun font-medium-2 mr-2"></i><span>{{
          this.translate('design-theme') }}</span>
      </h6>
      <div class="layout-switch">
        <div class="radio radio-sm d-inline-block light-layout mr-3">
          <input id="ll-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Light'"
            (click)="customizerService.switchLayout('light', isBgImageDisplay)">
          <label for="ll-switch">{{ this.translate('design-light') }}</label>
        </div>
        <div class="radio radio-sm d-inline-block dark-layout mr-3">
          <input id="dl-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Dark'"
            (click)="customizerService.switchLayout('dark', isBgImageDisplay)">
          <label for="dl-switch">{{ this.translate('design-dark') }}</label>
        </div>
        <div class="radio radio-sm d-inline-block transparent-layout">
          <input id="tl-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Transparent'"
            (click)="customizerService.switchLayout('transparent', isBgImageDisplay)">
          <label for="tl-switch">{{ this.translate('design-transparent') }}</label>
        </div>
      </div>
    </div>
    <!-- Navigation Options Starts-->
    <div class="ct-layout pr">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-layout font-medium-2 mr-2"></i><span>{{
          this.translate('design-alignment') }}</span></h6>
      <div class="menu-switch">
        <div class="radio radio-sm d-inline-block menu-side mr-3">
          <input id="menu-side" type="radio" name="menu-switch" [checked]="config.layout.menuPosition === 'Side'"
            (click)="customizerService.toggleMenuPosition('Side')">
          <label for="menu-side">{{ this.translate('design-vertical') }}</label>
        </div>
        <div class="radio radio-sm d-inline-block menu-top">
          <input id="menu-top" type="radio" name="menu-switch" [checked]="config.layout.menuPosition === 'Top'"
            (click)="customizerService.toggleMenuPosition('Top')">
          <label for="menu-top">{{ this.translate('design-horizontal') }}</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <!-- Navbar Types-->
    <div class="ct-navbar-type pr">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-more-horizontal font-medium-2 mr-2"></i><span>{{
          this.translate('design-navheader') }}</span></h6>
      <div class="navbar-switch">
        <div class="radio radio-sm d-inline-block nav-static mr-3">
          <input id="nav-static" type="radio" name="navbar-switch" [checked]="config.layout.navbar.type === 'Static'"
            (click)="customizerService.toggleNavbarType('Static')">
          <label for="nav-static">{{ this.translate('design-scroll') }}</label>
        </div>
        <div class="radio radio-sm d-inline-block nav-fixed">
          <input id="nav-fixed" type="radio" name="navbar-switch" [checked]="config.layout.navbar.type === 'Fixed'"
            (click)="customizerService.toggleNavbarType('Fixed')">
          <label for="nav-fixed">{{ this.translate('design-fixed') }}</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <div class="ct-bg-color pr">
      <hr>
      <h6 class="sb-options d-flex align-items-center mb-3"><i class="ft-droplet font-medium-2 mr-2"></i><span>{{
          this.translate('design-bgColor') }}</span></h6>
      <div class="cz-bg-color sb-color-options">
        <div class="row mb-3">
          <div class="col px-2" *ngFor="let color of customizerService.lightDarkLayoutGradientBGColors">
            <span class="{{color.class}} d-block rounded" style="width:30px; height:30px;"
              [attr.data-bg-color]="color.code" [ngClass]="{'selected': color.active}"
              (click)="customizerService.changeSidebarBGColor(color)">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col px-2" *ngFor="let color of customizerService.lightDarkLayoutSolidBGColors">
            <span class="{{color.class}} d-block rounded" style="width:30px; height:30px;"
              [attr.data-bg-color]="color.code" [ngClass]="{'selected': color.active}"
              (click)="customizerService.changeSidebarBGColor(color)">
            </span>
          </div>
        </div>
      </div>
      <!-- Sidebar Options Ends-->
      <!-- Transparent BG Image Ends-->
      <div class="tl-bg-img">
        <h6 class="d-flex align-items-center mb-3"><i class="ft-image font-medium-2 mr-2"></i><span>{{
            this.translate('design-bgImage') }}</span></h6>
        <div class="cz-tl-bg-image row">
          <div class="col-sm-3" *ngFor="let color of customizerService.transparentLayoutBGColorsWithShades">
            <div class="rounded {{color.class}} ct-glass-bg" [attr.data-bg-image]="color.class"
              [ngClass]="{'selected': color.active}" (click)="customizerService.changeSidebarTransparentBGColor(color)">
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent BG Image Ends-->
    </div>
    <!-- Sidebar BG Image Starts-->
    <div class="ct-bg-image pr">
      <hr>
      <h6 class="sb-bg-img d-flex align-items-center mb-3"><i class="ft-image font-medium-2 mr-2"></i><span>{{
          this.translate('design-bgImage') }}</span></h6>
      <div class="cz-bg-image row sb-bg-img">
        <div class="col-2 p-2" *ngFor="let img of customizerService.lightDarkLayoutBGImages">
          <img class="rounded" alt="sidebar bg image" [src]="img.src" width="90" [ngClass]="{'selected': img.active}"
            (click)="customizerService.changeSidebarBgImage(img)" />
        </div>
      </div>
      <!-- Transparent Layout Bg color Options-->
      <div class="tl-color-option">
        <h6 class="tl-color-options d-flex align-items-center mb-3"><i
            class="ft-droplet font-medium-2 mr-2"></i><span>{{ this.translate('design-bgColor') }}</span></h6>
        <div class="cz-tl-bg-color">
          <div class="row">
            <div class="col" *ngFor="let color of customizerService.transparentLayoutBGColors">
              <span class="{{color.class}} d-block rounded  ct-color-bg" style="width:30px; height:30px;"
                [attr.data-bg-color]="color.class" [ngClass]="{'selected': color.active}"
                (click)="customizerService.changeSidebarTransparentBGColor(color)">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent Layout Bg color Ends-->
    </div>
    <!-- Sidebar BG Image Toggle Starts-->
    <div class="ct-bg-image-toggler pr">
      <div class="togglebutton toggle-sb-bg-img">
        <div><span>{{ this.translate('design-enableBg') }}</span>
          <div class="float-right">
            <div class="checkbox">
              <input class="cz-bg-image-display" id="sidebar-bg-img" type="checkbox" checked
                (change)="bgImageDisplay($event)">
              <label for="sidebar-bg-img"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar BG Image Toggle Ends-->

    <!-- Sidebar Width Starts-->
    <div class="ct-compact-toggler pr">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-more-horizontal font-medium-2 mr-2"></i><span>{{
          this.translate("design-navSize") }}</span></h6>
      <div class="navbar-switch">

        <div class="radio radio-sm d-inline-block mr-3">
          <input id="nav-compact" type="radio" name="navbar-size" [checked]="config.layout.sidebar.collapsed"
            (change)="customizerService.toggleCompactMenu(config.layout.sidebar.collapsed)">
          <label for="nav-compact">{{ this.translate("design-nav-compact") }}</label>
        </div>
        <div class="radio radio-sm d-inline-block mr-3">
          <input id="nav-small" type="radio" name="navbar-size"
            [checked]="size === 'sidebar-sm' && !config.layout.sidebar.collapsed"
            (click)="changeSidebarWidth('sidebar-sm')">
          <label for="nav-small">{{ this.translate("design-nav-small") }}</label>
        </div>
        <div class="radio radio-sm d-inline-block mr-3">
          <input id="nav-medium" type="radio" name="navbar-size"
            [checked]="size === 'sidebar-md'  && !config.layout.sidebar.collapsed"
            (click)="changeSidebarWidth('sidebar-md')">
          <label for="nav-medium">{{ this.translate("design-nav-medium") }}</label>
        </div>
        <div class="radio radio-sm d-inline-block">
          <input id="nav-large" type="radio" name="navbar-size"
            [checked]="size === 'sidebar-lg'  && !config.layout.sidebar.collapsed"
            (click)="changeSidebarWidth('sidebar-lg')">
          <label for="nav-large">{{ this.translate("design-nav-large") }}</label>
        </div>

      </div>
    </div>

    <!-- Logo Override -->
    <div class="ct-logo-toggler pr">
      <hr>
      <div class="togglebutton">
        <div><span><i class="ft-image"></i> {{ this.translate("design-override-logo") }}</span>
          <div class="float-right">
            <div class="checkbox">
              <input class="cz-logo" id="cz-logo" type="checkbox" [checked]="config.layout.overrideLogo"
                (change)="customizerService.toggleLogoOverride($event.target.checked)">
              <label for="cz-logo"></label>
            </div>
          </div>
        </div>
      </div>
      <!-- Compact Menu Ends-->
    </div>
  </div>
</div>
<!-- Theme customizer Ends-->