import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/auth/auth.service';
import { ApiService } from './shared/services/api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authS: AuthService,
        private api: ApiService
    ) {
        //Check bei Route-Change ob Lizenz Valid
        router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const url = this.router.url;
                if(url == "/lizenz-abgelaufen"){
                    return;
                }else if(url == "/login"){
                    this.checkRedirect();
                }else{
                    return;
                }
            }
        });
    }

    checkRedirect(){
        this.api.sendAccountGET("account").subscribe(
            (response) => {
                if (
                    response["title"] == "Success" &&
                    response["message"]["de"] == "API-Schlüssel gültig" &&
                    response["message"]["en"] == "API-Key valid"
                ) {
                    this.router.navigate(["/dashboard"]);
                }
            },
            (err) => {
                if (err.error["message"]["de"] == "Ihre Lizenz ist abgelaufen!" && err.error["message"]["en"] == "Your license has expired!") {
                    this.router.navigate(["/lizenz-abgelaufen"]);
                }
            }
        );
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}