import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataCacheService } from './datacache.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.apiUrl;
  private host = `${this.apiUrl}/`;
  private headers: any;
  private options: any;

  constructor(
    private http: HttpClient,
    private dcs: DataCacheService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.dcs.load('api-key') + ''
    });
    this.options = { headers: this.headers };
  }

  public sendGET(api_version: string, endpoint: string): Observable<Object> {
    return this.http.get(this.host + api_version + '/' + endpoint, this.options);
  }

  public sendPOST(api_version: string, endpoint: string, data: any): Observable<Object> {
    return this.http.post(this.host + api_version + '/' + endpoint, data, this.options);
  }

  public sendPATCH(api_version: string, endpoint: string, data: any): Observable<Object> {
    return this.http.patch(this.host + api_version + '/' + endpoint, data, this.options);;
  }

  public sendDELETE(api_version: string, endpoint: string): Observable<Object> {
    return this.http.delete(this.host + api_version + '/' + endpoint, this.options);
  }

  public loadNews(): Observable<Object> {
    return this.http.get(this.host + 'news', this.options);
  }

  public sendAccountGET(endpoint: string): Observable<Object> {
    return this.http.get(this.host + endpoint, this.options);
  }

  public sendAccountPOST(endpoint: string, data: any): Observable<Object> {
    return this.http.post(this.host + endpoint, data, this.options);
  }

  public sendUpload(api_version: string, endpoint: string, data: any): Observable<Object> {
    const h = new HttpHeaders({
      'Authorization': this.dcs.load('api-key') + ''
    });

    return this.http.post(this.host + api_version + '/' + endpoint, data, {
      headers: h
    });
  }

  public sendDownload(api_version: string, endpoint: string, data: any): Observable<Object> {
    const h = new HttpHeaders({
      'Authorization': this.dcs.load('api-key') + ''
    });

    return this.http.post(this.host + api_version + '/' + endpoint, data, {
      headers: h,
      responseType: 'arraybuffer'
    });
  }

}
